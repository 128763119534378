import { MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useSWR from 'swr';
import Modal from '../../components/modal';
import { useRoles } from '../../swr/use-roles';
import { Profile } from '../../types';
import styles from './detail.module.css';

type Props = {
  visible: boolean;
  onClose: () => void;
  userId?: string;
};

type Form = {
  email: string;
  password: string;
  name: string;
  role: string;
};

const UserDetail = ({ visible, onClose, userId }: Props) => {
  const { register, getValues, trigger, reset, control } = useForm<Form>({
    defaultValues: { role: 'basic' },
  });
  const { data: user } = useSWR<Profile>(userId ? `/users/${userId}` : null);
  const { enqueueSnackbar } = useSnackbar();
  const roles = useRoles();

  useEffect(() => {
    reset(
      user ?? {
        email: '',
        password: '',
        name: '',
        role: 'basic',
      }
    );
  }, [user]);

  const onSubmit = async () => {
    if (!(await trigger(undefined, { shouldFocus: true }))) {
      return false;
    }

    if (userId) {
      await axios.put(`/users/${userId}`, getValues());
      enqueueSnackbar('Updated.', { variant: 'success' });
    } else {
      await axios.post('/users', getValues());
      enqueueSnackbar('User added.', { variant: 'success' });
    }
    return true;
  };

  return (
    <Modal open={visible} onClose={onClose} title="New user" onOk={onSubmit}>
      <div className={styles.row}>
        <TextField
          fullWidth
          type="email"
          label="Email"
          {...register('email', { required: true })}
        />
      </div>
      <div className={styles.row}>
        <TextField
          fullWidth
          label="Password"
          {...register('password', { required: true })}
        />
      </div>
      <div className={styles.row}>
        <TextField
          fullWidth
          label="Name"
          {...register('name', { required: true })}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name="role"
          render={({ field: { value, onChange } }) => (
            <Select
              fullWidth
              value={value}
              onChange={(event) => {
                onChange(event.target.value);
              }}
            >
              {roles.map((role) => (
                <MenuItem value={role} key={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </div>
    </Modal>
  );
};

export default UserDetail;
