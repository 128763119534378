import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { BaseStyles, ThemeProvider } from "@primer/react";
import { SnackbarProvider } from "notistack";
import { SWRConfig } from "swr";
import App from "./App";
import "./index.css";
import swrConfig from "./swr-config";
import "./parcel.d";

const app = document.querySelector("#root");
const root = app && createRoot(app);
root?.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <BaseStyles>
          <SWRConfig value={swrConfig}>
            <SnackbarProvider
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={3000}
            >
              <App />
            </SnackbarProvider>
          </SWRConfig>
        </BaseStyles>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
