import {
  Button,
  Label,
  SelectMenu,
  SideNav,
  Spinner,
  Text,
} from "@primer/react";
import { MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useSWR from "swr";
import useProfile from "../../hooks/use-profile";
import { Tenant } from "../../types";
import { isUserAdmin } from "../../utils/is-user-admin";
import { adminRoutes, basicRoutes, getActiveRoute } from "./config";
import styles from "./index.module.css";

const Sidebar = () => {
  const profile = useProfile();
  const navigate = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState<string>();
  const { data: tenant } = useSWR<Tenant>("/tenant");

  const isAdmin = profile && isUserAdmin(profile);
  const routes = isAdmin ? adminRoutes : basicRoutes;
  const activeRoute = getActiveRoute(pathname);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const handleNavigate = (event: MouseEvent, url: string) => {
    event.preventDefault();
    setPathname(url);
    navigate(url);
  };

  const handleSignOut = () => {
    localStorage.removeItem("shark-token");
    navigate("/sign-in");
  };

  if (!profile || !tenant) {
    return (
      <nav className={styles.nav}>
        <Spinner />
      </nav>
    );
  }

  return (
    <nav className={styles.nav}>
      <div className={styles.tenant}>
        <div className={styles.tenantIcon}>
          <img src={tenant.photo} alt={tenant.name} />
        </div>
        <div className={styles.tenantName}>{tenant.name}</div>
        <div className={styles.space} />
        <SelectMenu>
          <Button as="summary" className={styles.profile}>
            <img src={profile.picture} alt={profile.name} />
          </Button>
          <SelectMenu.Modal>
            <SelectMenu.Header>
              {profile.name}({profile.email})
              {isAdmin && (
                <Label variant="medium" sx={{ bg: "#A575FF", m: 1 }}>
                  admin
                </Label>
              )}
            </SelectMenu.Header>
            <SelectMenu.Item onClick={() => alert("not implemented")}>
              View profile
            </SelectMenu.Item>
            <SelectMenu.Item onClick={handleSignOut}>Sign out</SelectMenu.Item>
          </SelectMenu.Modal>
        </SelectMenu>
      </div>
      <SideNav bordered className={styles.mainNav}>
        {routes.map((route) => (
          <SideNav.Link
            key={route.path}
            href={route.path}
            onClick={(e) => handleNavigate(e, route.path)}
            selected={activeRoute?.path === route.path}
          >
            <div className={styles.navItem}>
              {route.icon}
              <Text>{route.title}</Text>
            </div>
          </SideNav.Link>
        ))}
      </SideNav>
    </nav>
  );
};

export default Sidebar;
