import { Button, Input } from "@mui/material";
import { Breadcrumbs } from "@primer/react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormGroup from "../../components/form-group";
import { Order } from "../../types/order";
import { localInputDateToRemote } from "../../utils/date-input";
import styles from "./create-order.module.css";

type Form = {
  mbl: string;
  cntr: string;
  ref: string;
  etaToPort: string;
};

const PageCreateOrder = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<Form>();

  const onSubmit = async (data: Form) => {
    const {
      data: { id },
    } = await axios.post<Order>(`/orders`, {
      ...data,
      etaToPort: localInputDateToRemote(data.etaToPort),
    });
    navigate(`/orders/${id}`);
  };

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item href="#" onClick={() => navigate("/orders")}>
          Home
        </Breadcrumbs.Item>
        <Breadcrumbs.Item selected>New Order</Breadcrumbs.Item>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItems}>
          <FormGroup label="MBL">
            <Controller
              name="mbl"
              control={control}
              render={({ field }) => <Input fullWidth {...field} />}
            />
          </FormGroup>
          <FormGroup label="CNTR">
            <Controller
              name="cntr"
              control={control}
              render={({ field }) => <Input fullWidth {...field} />}
            />
          </FormGroup>
          <FormGroup label="REF">
            <Controller
              name="ref"
              control={control}
              render={({ field }) => <Input fullWidth {...field} />}
            />
          </FormGroup>
          <FormGroup label="ETA to port">
            <Controller
              name="etaToPort"
              control={control}
              render={({ field }) => <Input fullWidth type="date" {...field} />}
            />
          </FormGroup>
        </div>
        <div className={styles.actions}>
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PageCreateOrder;
