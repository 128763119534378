import DocumentColumnsPage from './document-columns';
import TenantInfo from './tenant-info';

const PageSettings = () => (
  <div>
    <TenantInfo />
    <DocumentColumnsPage />
  </div>
);

export default PageSettings;
