import { Routes, Route } from "react-router";
import styles from './index.module.css';
import PageAccoutingItems from "./accounting-items";
import PageCreateAccountingItem from "./accounting-items/create";
import PageAccountingItem from "./accounting-items/detail";
import PageAddresses from "./addresses";
import PageCreateAddress from "./addresses/create";
import PageAddress from "./addresses/detail";
import PageCustomers from "./customers";
import PageCreateCustomer from "./customers/create";
import PageCustomer from "./customers/detail";
import PageDepots from "./depots";
import PageCreateDepot from "./depots/create";
import PageDepot from "./depots/detail";
import PageHome from "./home";
import PageCreateOrder from "./home/create-order";
import PageDocument from "./home/document";
import PageInvoices from "./home/invoices";
import PageOrder from "./home/order";
import PageSettings from "./settings";
import PageEditDocumentColumn from "./settings/edit-document-column";
import PageSsls from "./ssls";
import PageCreateSsl from "./ssls/create";
import PageSsl from "./ssls/detail";
import PageTerminals from "./terminals";
import PageCreateTerminal from "./terminals/create";
import PageTerminal from "./terminals/detail";
import PageUsers from "./users";
import PageVendors from "./vendors";
import PageCreateVendor from "./vendors/create";
import PageVendor from "./vendors/detail";

const PagesContainer = () => (
  <main className={styles.main}>
    <Routes>
      <Route path="/" element={<PageHome />} />
      <Route path="/orders" element={<PageHome />} />
      <Route path="/orders/create" element={<PageCreateOrder />} />
      <Route path="/orders/:orderId" element={<PageOrder />} />
      <Route
        path="/orders/:orderId/documents/:documentId"
        element={<PageDocument />}
      />
      <Route path="/orders/:orderId/invoices" element={<PageInvoices />} />
      <Route path="/settings" element={<PageSettings />} />
      <Route
        path="/settings/document-columns/:columnId"
        element={<PageEditDocumentColumn />}
      />
      <Route path="/users" element={<PageUsers />} />
      <Route path="/customers/create" element={<PageCreateCustomer />} />
      <Route path="/customers/:customerId" element={<PageCustomer />} />
      <Route path="/customers" element={<PageCustomers />} />
      <Route path="/vendors/create" element={<PageCreateVendor />} />
      <Route path="/vendors/:vendorId" element={<PageVendor />} />
      <Route path="/vendors" element={<PageVendors />} />
      <Route path="/ssls/create" element={<PageCreateSsl />} />
      <Route path="/ssls/:sslId" element={<PageSsl />} />
      <Route path="/ssls" element={<PageSsls />} />
      <Route path="/addresses/create" element={<PageCreateAddress />} />
      <Route path="/addresses/:addressId" element={<PageAddress />} />
      <Route path="/addresses" element={<PageAddresses />} />
      <Route path="/depots/create" element={<PageCreateDepot />} />
      <Route path="/depots/:depotId" element={<PageDepot />} />
      <Route path="/depots" element={<PageDepots />} />
      <Route path="/terminals/create" element={<PageCreateTerminal />} />
      <Route path="/terminals/:terminalId" element={<PageTerminal />} />
      <Route path="/terminals" element={<PageTerminals />} />
      <Route
        path="/accounting-items/create"
        element={<PageCreateAccountingItem />}
      />
      <Route
        path="/accounting-items/:itemId"
        element={<PageAccountingItem />}
      />
      <Route path="/accounting-items" element={<PageAccoutingItems />} />
    </Routes>
  </main>
);

export default PagesContainer;
