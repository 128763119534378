import { PlusIcon } from '@primer/octicons-react';
import { Button } from '@primer/react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { Customer } from '../../types';
import Table from '../../components/table';
import styles from './style.module.css';

const PageCustomers = () => {
  const { data: customers } = useSWR<Customer[]>('/customers');

  const columns = [
    { name: 'code', className: styles.code },
    { name: 'name' },
    { name: 'address', className: styles.address, flex: true },
    {
      name: 'other',
      render: (_: any, row: Customer) => (
        <div className={styles.other}>
          <div>{row.phone}</div>
          <div>{row.email}</div>
          <div>{row.contact}</div>
        </div>
      ),
    },
    {
      name: 'id',
      render: (id: string) => <Link to={`/customers/${id}`}>view</Link>,
    },
  ];

  const title = (
    <div className={styles.title}>
      <div>Customers</div>
      <div className={styles.actions}>
        <Button>
          <Link to="/customers/create" className={styles.create}>
            <PlusIcon />
            New Customer
          </Link>
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Table columns={columns} rows={customers || []} title={title} />
    </div>
  );
};

export default PageCustomers;
