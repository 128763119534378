.page {
  width: 100%;
  display: grid;
  place-items: center;
}

.form {
  width: 300px;
}

.formRow {
  margin-bottom: 8px;
}

.submit {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
