.wrapper {
  margin: 32px 0;
}
.note {
  border: 1px solid rgb(84, 174, 255);
  margin-bottom: 16px;
  border-radius: 6px;
  overflow: hidden;
}
.header {
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  background: rgb(221, 244, 255);
  border-bottom: 1px solid rgb(84, 174, 255);
}
.meta {
  margin-left: 8px;
  color: rgb(87, 96, 106);
}
.content {
  padding: 16px
}
.images {
  display: flex;
}
.images img {
  width: 100px;
  height: auto;
  margin: 16px;
}
.create {
  margin: 16px 0;
}
.actions {
  text-align: right;
  margin-top: 16px;
}