.formItems {
  padding-top: 16px;
}

.formItems > * {
  margin-bottom: 16px;
}

.actions {
  margin-top: 16px;
}
