import useSWR from 'swr';
import { Profile } from '../../types';

interface Props {
  userId?: string;
}

const Driver = ({ userId }: Props) => {
  const { data } = useSWR<Profile>(userId ? `/users/${userId}` : null);
  if (!data) {
    return <span />;
  }
  return <span>{data.name}</span>;
};

export default Driver;
