import moment from "moment";
import axios from "axios";
import useSWR from "swr";
import { Note } from "../../types";
import Driver from "../driver";
import styles from "./style.module.css";
import ImageUpload from "../image-upload";
import { Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

interface Props {
  orderId: string;
}

type Form = {
  content: string;
  image: string;
};

export default function Notes({ orderId }: Props) {
  const { data: notes, mutate } = useSWR<Note[]>(`/orders/${orderId}/notes`);
  const { enqueueSnackbar } = useSnackbar();
  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Form>();

  const onSubmit = async (data: Form) => {
    await axios.post(`/orders/${orderId}/notes`, {
      content: data.content,
      images: data.image ? [data.image] : [],
    });
    mutate();
    reset();
    enqueueSnackbar("Saved", { variant: "success" });
  };

  if (!notes) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {notes.map((note) => (
        <div className={styles.note} key={note.id}>
          <div className={styles.header}>
            <Driver userId={note.userId} />
            <div className={styles.meta}>
              {moment(note.createdAt).format("YYYY-MM-DD HH:mm")}
            </div>
          </div>
          <div className={styles.content}>{note.content}</div>
          <div className={styles.images}>
            {note.images.map((url) => (
              <img src={url} key={url} alt="" />
            ))}
          </div>
        </div>
      ))}
      <div className={styles.create}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField multiline fullWidth minRows={5} {...register("content", { required: true })} />
          <Controller
            control={control}
            name="image"
            render={({ field }) => <ImageUpload {...field} />}
          />
          <div className={styles.actions}>
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Submit Note
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
