.tip {
  padding: 16px 0;
  font-size: 14px;
}
.page {
  display: flex;
  flex-wrap: wrap;
}
.main {
  flex: 1;
}
.meta {
  font-size: 12px;
  color: rgb(87, 96, 106);
  width: 280px;
  padding: 0 0 0 20px;
}
.meta h2 {
  font-size: 14px;
  margin-top: 20px;
}
.meta hr {
  font-size: 14px;
  margin-top: 20px;
}
.attachment {
  margin-bottom: 8px;
}
.attachment img {
  width: 160px;
  height: auto;
  border-radius: 5px;
}
.signature {
  margin-bottom: 8px;
}
.signature img {
  width: 160px;
  height: auto;
  border-radius: 5px;
}
