.nav {
  width: 200px;
  height: 100%;
  user-select: none;
  border-right: 1px solid rgb(239, 241, 244);
  padding: 20px 10px;
}
.tenant {
  display: flex;
  align-items: center;
}
.tenantIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 4px;
}
.tenantIcon img {
  width: 24px;
  height: auto;
}
.space {
  flex: 1;
}
.tenantName {
  font-size: 14px;
  font-weight: 600;
}
.profile {
  width: 24px;
  height: 24px;
  padding: 0 !important;
  border: none !important;
  overflow: hidden;
}
.profile img {
  width: 24px;
  height: auto;
}
.mainNav {
  margin-top: 60px;
}
.navItem {
  display: flex;
  align-items: center;
  gap: 8px;
}