import { PlusIcon } from "@primer/octicons-react";
import { Button } from "@primer/react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { Vendor } from "../../types";
import Table from "../../components/table";
import styles from "./style.module.css";

const PageVendors = () => {
  const { data: vendors } = useSWR<Vendor[]>("/vendors");

  const columns = [
    { name: "code", className: styles.code },
    { name: "name" },
    { name: "address", className: styles.address, flex: true },
    {
      name: "other",
      render: (_: any, row: Vendor) => (
        <div className={styles.other}>
          <div>{row.phone}</div>
          <div>{row.email}</div>
          <div>{row.contact}</div>
        </div>
      ),
    },
    {
      name: "id",
      render: (id: string) => <Link to={`/vendors/${id}`}>view</Link>,
    },
  ];

  const title = (
    <div className={styles.title}>
      <div>Vendors</div>
      <div className={styles.actions}>
        <Button>
          <Link to="/vendors/create" className={styles.create}>
            <PlusIcon />
            New Vendor
          </Link>
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Table columns={columns} rows={vendors || []} title={title} />
    </div>
  );
};

export default PageVendors;
