import { Autocomplete, Box, TextField } from "@mui/material";
import { AxiosError } from "axios";
import useSWR from "swr";
import { AccountingItem } from "../../types";

interface Props {
  value: string | null;
  onChange: (value: string | null) => void;
  isCost: boolean;
}

const AccountingItemSelect = ({ value, onChange, isCost }: Props) => {
  const { data, error } = useSWR<AccountingItem[], AxiosError>(
    "/accounting-items"
  );

  const loading = !data && !error;

  const valueOption =
    value && data ? data.find(({ id }) => id === value) : null;

  return (
    <Autocomplete
      disablePortal
      autoHighlight
      value={valueOption}
      loading={loading}
      renderInput={(parameters) => (
        <TextField {...parameters} variant="standard" size="small" />
      )}
      getOptionLabel={({ title, number }) => `${number} - ${title}`}
      renderOption={(props, { id, title, number }) => (
        <Box component="li" {...props} key={id}>
          {number} - {title}
        </Box>
      )}
      isOptionEqualToValue={({ id }, { id: id_ }) => id === id_}
      options={data ? data.filter((item) => isCost === item.isCost) : []}
      onChange={(_: unknown, value_) => {
        onChange(value_ ? value_.id : null);
      }}
    />
  );
};

export default AccountingItemSelect;
