.card {
  width: 600px;
  margin: 100px auto 0;
}

.content {
  padding: 16px;
}

.loading {
  display: flex;
}

.message {
  margin-left: 16px;
  padding-top: 16px;
}

.footer {
  padding: 16px;
  border-top: 1px solid #d9dee3;
  display: flex;
  justify-content: right;
}
