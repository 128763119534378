.card {
  margin: 100px auto 0;
	width: 600px;
}

.header {
	padding: 8px 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #e0e0e0;
}

.title {
	flex: 1;
	font-size: 20px;
}

.body {
	padding: 16px;
}

.footer {
	padding: 16px;
	display: flex;
	justify-content: right;
	border-top: 1px solid #e0e0e0;
}

.footer > button {
	margin-left: 8px;
}
