import { Chip } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { OrderContainerForm } from "../../types";

const TerminalDays = () => {
  const { watch } = useFormContext<OrderContainerForm>();
  const lfd = watch("lfd");
  const pickupDate = watch("pickupDate");

  const dayCount = useMemo(() => {
    if (!lfd) {
      return null;
    }

    const lfdMoment = moment(lfd);

    if (!lfdMoment.isValid()) {
      return null;
    }

    // (LFD日期-当天日期) 若数值是正数或者0则+1, 若数值是负数则+0. -> da -> db
    // (LFD日期-P/U D日期) 若数值是正数或者0则+1, 若数值是负数则+0. -> dc -> dd
    // 两者取最大值显示, 注意是最大值
    // 显示的天数小于等于1, 则红字显示
    const pickupMoment = pickupDate ? moment(pickupDate) : moment();
    const da =
      (lfdMoment.startOf("day").unix() - moment().startOf("day").unix()) /
      86_400;
    // eslint-disable-next-line unicorn/prevent-abbreviations
    const db = da >= 0 ? da + 1 : da;
    const dc =
      (lfdMoment.startOf("day").unix() - pickupMoment.startOf("day").unix()) /
      86_400;
    const dd = dc >= 0 ? dc + 1 : dc;

    return Math.max(db, dd);
  }, [lfd, pickupDate]);

  return (
    <Chip
      size="small"
      sx={{ width: "100%" }}
      label={`TEML FREE DAYS: ${dayCount === null ? "-" : dayCount}`}
      color={dayCount !== null && dayCount <= 1 ? "error" : "default"}
    />
  );
};

export default TerminalDays;
