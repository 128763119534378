import { Breadcrumbs } from "@primer/react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import axios from "axios";
import useSWR from "swr";
import { Ssl } from "../../types";
import { useSnackbar } from "notistack";
import CommonFormFields from "../../components/common-form-fields";
import { CommonForm } from "../../components/common-form-fields/types";
import { basePath } from "./constants";

const PageSsl = () => {
  const navigate = useNavigate();
  const { sslId } = useParams();
  const { data: ssl, mutate } = useSWR<Ssl>(
    sslId ? `${basePath}/${sslId}` : null
  );
  const formMethods = useForm<CommonForm>();
  const { handleSubmit, setValue } = formMethods;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!ssl) {
      return;
    }

    const { name, short, phone, email, contactPerson } = ssl;
    setValue("name", name);
    setValue("short", short ?? "");
    setValue("address", ssl.address ?? "");
    setValue("phone", phone ?? "");
    setValue("email", email ?? "");
    setValue("contactPerson", contactPerson ?? "");
  }, [ssl]);

  const onSubmit = async (data: CommonForm) => {
    const { data: response } = await axios.put<Ssl>(
      `${basePath}/${sslId}`,
      data
    );
    mutate(response);
    enqueueSnackbar("Saved", { variant: "success" });
  };

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item href="#" onClick={() => navigate(basePath)}>
          SSLs
        </Breadcrumbs.Item>
        <Breadcrumbs.Item selected>{ssl?.name}</Breadcrumbs.Item>
      </Breadcrumbs>
      {ssl && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CommonFormFields submitLabel="Save" />
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default PageSsl;
