import { Breadcrumbs } from "@primer/react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import axios from "axios";
import useSWR from "swr";
import { Depot } from "../../types";
import { useSnackbar } from "notistack";
import CommonFormFields from "../../components/common-form-fields";
import { CommonForm } from "../../components/common-form-fields/types";
import { basePath } from "./constants";

const PageDepot = () => {
  const navigate = useNavigate();
  const { depotId } = useParams();
  const { data: depot, mutate } = useSWR<Depot>(
    depotId ? `${basePath}/${depotId}` : null
  );
  const formMethods = useForm<CommonForm>();
  const { handleSubmit, setValue } = formMethods;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!depot) {
      return;
    }

    const { name, short, phone, email, contactPerson } = depot;
    setValue("name", name);
    setValue("short", short ?? "");
    setValue("address", depot.address ?? "");
    setValue("phone", phone ?? "");
    setValue("email", email ?? "");
    setValue("contactPerson", contactPerson ?? "");
  }, [depot]);

  const onSubmit = async (data: CommonForm) => {
    const { data: response } = await axios.put<Depot>(
      `${basePath}/${depotId}`,
      data
    );
    mutate(response);
    enqueueSnackbar("Saved", { variant: "success" });
  };

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item href="#" onClick={() => navigate(basePath)}>
          Depots
        </Breadcrumbs.Item>
        <Breadcrumbs.Item selected>{depot?.name}</Breadcrumbs.Item>
      </Breadcrumbs>
      {depot && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CommonFormFields submitLabel="Save" />
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default PageDepot;
