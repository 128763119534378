import { PlusIcon } from "@primer/octicons-react";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { renderDate, wrapOrderSerial } from "shared";
import useSWR from "swr";
import DataTable from "../../components/data-table";
import { Tenant } from "../../types";
import { Order } from "../../types/order";
import styles from "./style.module.css";
import { Button } from "@mui/material";
import OrderFilter, { OrderFilterData } from "./components/filter";
import { queryStringify } from "../../utils/query-stringify";
import {
  DataTableColumn,
  DataTableColumnConfig,
  DataTableSorter,
} from "../../components/data-table/types";
import { defaultOrderColumnIds, orderColumns } from "./constant";
import useLocalConfigs from "../../hooks/use-local-configs";

const PageHome = () => {
  const [pageIndex, setPageIndex] = useLocalConfigs<number>("order-page", 0);
  const [sorter, setSorter] = useLocalConfigs<DataTableSorter>("order-sorter", {
    key: "number",
    by: "desc",
  });
  const [pageSize, setPageSize] = useLocalConfigs<number>(
    "order-page-size",
    10
  );
  const [filter, setFilter] = useLocalConfigs<Partial<OrderFilterData>>(
    "order-filter",
    {}
  );
  const { data: ordersResponse, error: ordersError } = useSWR<
    [Order[], number]
  >(
    `/orders?offset=${pageIndex * pageSize}&limit=${pageSize}&sortId=${
      sorter.key
    }&sortDesc=${sorter.by === "desc"}${queryStringify(filter)}`
  );
  const { data: tenant } = useSWR<Tenant>("/tenant");
  const navigate = useNavigate();
  const [configColumns, setConfigColumns] = useLocalConfigs<
    DataTableColumnConfig[]
  >("order-columns", []);

  const handleConfigColumnsChange = (value: DataTableColumnConfig[]) => {
    setConfigColumns(value);
  };

  const handleSorterChange = (id: string, direction: "asc" | "desc") => {
    setSorter({
      key: id,
      by: direction,
    });
  };

  const handleOrderClick = (id: string) => {
    navigate(`/orders/${id}`);
  };

  const columns = useMemo((): DataTableColumn[] => {
    const computedColumns = configColumns
      .slice()
      .filter(({ enabled }) => enabled)
      .sort((column1, column2) => column1.order - column2.order)
      .map((configColumn) => {
        const sourceColumn = orderColumns.find(
          (column) => column.id === configColumn.id
        );

        if (!sourceColumn) {
          return null;
        }

        return {
          ...sourceColumn,
          width: configColumn.width,
        };
      })
      .filter((column): column is DataTableColumn => Boolean(column));

    if (computedColumns.length === 0) {
      return defaultOrderColumnIds
        .map((id) => orderColumns.find((column) => column.id === id))
        .filter((column): column is DataTableColumn => Boolean(column));
    }

    return computedColumns;
  }, [configColumns]);

  const orders = useMemo(() => {
    if (!ordersResponse) {
      return [];
    }
    return ordersResponse[0];
  }, [ordersResponse]);

  const totalCount = useMemo(() => {
    if (!ordersResponse) {
      return 0;
    }
    return ordersResponse[1];
  }, [ordersResponse]);

  const rows = useMemo(() => {
    if (!tenant) {
      return [];
    }
    return orders.map((row) => {
      const { short } = tenant;
      const { number, etaToPort, etaDischarged } = row;
      return {
        ...row,
        number: wrapOrderSerial(short, number),
        etaToPort: renderDate(etaToPort),
        etaDischarged: renderDate(etaDischarged),
      };
    });
  }, [orders, tenant]);

  return (
    <>
      <div className={styles.operations}>
        <Button variant="contained">
          <Link to="/orders/create" className={styles.create}>
            <PlusIcon />
            New
          </Link>
        </Button>
      </div>
      <OrderFilter value={filter} onChange={setFilter} />
      <div className={styles.space} />
      <DataTable
        pageSize={pageSize}
        columns={columns}
        isLoading={!ordersResponse && !ordersError}
        pageIndex={pageIndex}
        totalCount={totalCount}
        rows={rows}
        sorter={sorter}
        onSorterChange={handleSorterChange}
        onPageIndexChange={setPageIndex}
        onPageSizeChange={setPageSize}
        onClick={handleOrderClick}
        allColumns={orderColumns}
        configColumns={configColumns}
        onConfigColumnsChange={handleConfigColumnsChange}
      />
    </>
  );
};

export default PageHome;
