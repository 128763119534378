import { Breadcrumbs } from "@primer/react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import { Button, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { AccountingItem } from "../../types";
import { useSnackbar } from "notistack";
import styles from "./detail.module.css";

type Form = {
  number: string;
  title: string;
  type: string;
};

const PageAccountingItem = () => {
  const navigate = useNavigate();
  const { itemId } = useParams();
  const { data: item, mutate } = useSWR<AccountingItem>(
    itemId ? `/accounting-items/${itemId}` : null
  );
  const { handleSubmit, setValue, register } = useForm<Form>();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!item) {
      return;
    }

    const { number, title, type } = item;
    setValue("number", number);
    setValue("title", title);
    setValue("type", type);
  }, [item]);

  const onSubmit = async (data: Form) => {
    setLoading(true);
    await axios.put(`/accounting-items/${itemId}`, data);
    enqueueSnackbar("Saved", { variant: "success" });
    await mutate();
    setLoading(false);
  };

  const handleDelete = async () => {
    if (!confirm(`Are you sure to delete?`)) {
      return;
    }

    setLoading(true);
    await axios.delete(`/accounting-items/${itemId}`);
    setLoading(false);
    navigate("/accounting-items");
  };

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item
          href="#"
          onClick={() => navigate("/accounting-items")}
        >
          Accounting
        </Breadcrumbs.Item>
        <Breadcrumbs.Item selected>{item && item.number}</Breadcrumbs.Item>
      </Breadcrumbs>
      <div className={styles.form}>
        {item && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formField}>
              <TextField
                fullWidth
                id="number"
                label="Code"
                required
                {...register("number", { required: true })}
              />
            </div>
            <div className={styles.formField}>
              <TextField
                fullWidth
                id="title"
                label="Title"
                required
                {...register("title", { required: true })}
              />
            </div>
            <div className={styles.formField}>
              <TextField
                fullWidth
                id="type"
                label="Custom Type"
                {...register("type")}
              />
            </div>
            <Button variant="contained" type="submit" disabled={loading}>
              Save
            </Button>
            <div style={{ marginTop: "16px" }}>
              <Button
                variant="text"
                startIcon={<Delete />}
                disabled={loading}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default PageAccountingItem;
