import { PlusIcon } from "@primer/octicons-react";
import { Button } from "@primer/react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { AccountingItem } from "../../types";
import Table from "../../components/table";
import styles from "./style.module.css";
import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { useMemo } from "react";

const PageAccoutingItems = () => {
  const { data: items } = useSWR<AccountingItem[]>("/accounting-items");
  const [isCost, setIsCost] = useState(false);

  const columns = [
    { name: "number" },
    { name: "title" },
    { name: "type" },
    {
      name: "id",
      render: (id: string) => <Link to={`/accounting-items/${id}`}>view</Link>,
    },
  ];

  const title = (
    <div className={styles.title}>
      <div>Accounting</div>
      <div className={styles.actions}>
        <Button>
          <Link to="/accounting-items/create" className={styles.create}>
            <PlusIcon />
            New Item
          </Link>
        </Button>
      </div>
    </div>
  );

  const filteredItems = useMemo(() => {
    if (!items) {
      return [];
    }

    return items.filter((item) => item.isCost === isCost);
  }, [items, isCost]);

  return (
    <div>
      <Tabs
        value={isCost ? 1 : 0}
        onChange={(_, value) => {
          setIsCost(value === 1);
        }}
      >
        <Tab label="Sell" />
        <Tab label="Cost" />
      </Tabs>
      <Table columns={columns} rows={filteredItems} title={title} />
    </div>
  );
};

export default PageAccoutingItems;
