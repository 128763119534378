import { Chip } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { OrderContainerForm } from "../../types";

const CntrUseDays = () => {
  const { watch } = useFormContext<OrderContainerForm>();
  const pickupDate = watch("pickupDate");
  const emptyReturnDate = watch("emptyReturnDate");

  const dayCount = useMemo(() => {
    if (!pickupDate) {
      return 0;
    }

    const pickupMoment = moment(pickupDate);

    if (!pickupMoment.isValid()) {
      return 0;
    }

    const erMoment = emptyReturnDate ? moment(emptyReturnDate) : moment();
    const startUnix = Math.min(
      moment().startOf("day").unix(),
      erMoment.startOf("day").unix()
    );

    const seconds = startUnix - pickupMoment.startOf("day").unix();

    if (seconds < 0) {
      return 0;
    }

    return Math.ceil(seconds / 86_400) + 1;
  }, [emptyReturnDate, pickupDate]);

  return (
    <Chip
      size="small"
      sx={{ width: "100%" }}
      label={`CNTR USE DAYS: ${dayCount === 0 ? "-" : dayCount}`}
      color={dayCount >= 6 ? "error" : "default"}
    />
  );
};

export default CntrUseDays;
