import { CircularProgress } from '@mui/material';
import styles from './index.module.css';

interface Props {
  height?: number;
}

const Loading = ({ height }: Props) => (
  <div
    className={styles.loading}
    style={{ height: `${height || 200}px` }}
  >
    <CircularProgress />
  </div>
);

export default Loading;
