import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Sidebar from './components/sidebar';
import './App.css';
import PagesContainer from './pages';
import GlobalError from './components/global-error';
import PageSignIn from './pages/sign-in';
import Guard from './components/guard';
import axiosConfig from './axios-config';
import GlobalLoading from './components/global-loading';

function App() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const prepare = async () => {
      await axiosConfig();
      setReady(true);
    };

    prepare();
  }, []);

  if (!window.remote && process.env.NODE_ENV === 'production') {
    return <GlobalError title="Please Download APP" />;
  }

  if (!ready) {
    return <GlobalLoading />;
  }

  return (
    <div className="app">
      <Routes>
        <Route path="/sign-in" element={<PageSignIn />} />
        <Route
          path="*"
          element={
            <Guard>
              <Sidebar />
              <PagesContainer />
            </Guard>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
