import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

import { DataTableColumn, DataTableSorter } from './types';

type Props = {
  columns: DataTableColumn[];
  sorter?: DataTableSorter;
  onSorterChange?: (id: string, direction: "asc" | "desc") => void;
};

const Head = ({ columns, sorter, onSorterChange }: Props) => {
  const handleSorterClick = (id: string) => {
    if (!sorter || !onSorterChange) {
      return;
    }

    if (sorter.key === id) {
      onSorterChange(id, sorter.by === "desc" ? "asc" : "desc");
    } else {
      onSorterChange(id, "desc");
    }
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => {
          if (!sorter || !column.sortable) {
            return (
              <TableCell key={column.id} padding="none">
                <div style={{ width: `${column.width}px`, padding: "4px 8px" }}>
                  {column.label}
                </div>
              </TableCell>
            );
          }

          return (
            <TableCell
              key={column.id}
              padding="none"
              sortDirection={sorter.key === column.id ? sorter.by : false}
            >
              <div style={{ width: `${column.width}px`, padding: "4px 8px" }}>
                <TableSortLabel
                  active={sorter.key === column.id}
                  direction={sorter.key === column.id ? sorter.by : "asc"}
                  onClick={() => {
                    if (column.id && column.id !== "invoice") {
                      handleSorterClick(column.id);
                    }
                  }}
                >
                  {column.label}
                </TableSortLabel>
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default Head;
