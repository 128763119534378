import { ReactNode } from 'react';
import cx from 'classnames';
import { Button } from '@primer/react';
import { ArrowDownIcon, ArrowUpIcon, TrashIcon } from '@primer/octicons-react';
import styles from './style.module.css';

interface Props<T> {
  title: string | ReactNode;
  rows: Array<T>;
  columns: Array<{
    name: string;
    className?: string;
    flex?: boolean;
    width?: number;
    render?: (value: any, row: T) => ReactNode;
  }>;
  sortKey?: string;
  onSort?: (id: string, targetId: string) => void;
  onDelete?: (id: string) => void;
}

export default function Table<T extends { id: string; [key: string]: any }>({
  columns,
  rows,
  title,
  sortKey,
  onSort = () => {},
  onDelete,
}: Props<T>) {
  const sortedRows = rows.sort((a, b) => {
    if (!sortKey) {
      return 1;
    }
    return a[sortKey] - b[sortKey];
  });

  return (
    <div>
      <div className={styles.header}>{title}</div>
      <ul className={styles.list}>
        {sortedRows.map((row, index) => (
          <li key={row.id}>
            {columns.map((column) => (
              <div
                className={cx(
                  styles.cell,
                  column.flex && styles.flex,
                  column.className
                )}
                key={column.name}
                style={{ width: column.width ?? 'auto' }}
              >
                {column.render
                  ? column.render(row[column.name], row)
                  : row[column.name]}
              </div>
            ))}
            <div className={styles.actions}>
              {sortKey && (
                <Button
                  disabled={index === 0}
                  onClick={() => onSort(row.id, sortedRows[index - 1].id)}
                >
                  <ArrowUpIcon />
                </Button>
              )}
              {sortKey && (
                <Button
                  disabled={index === sortedRows.length - 1}
                  onClick={() => onSort(row.id, sortedRows[index + 1].id)}
                >
                  <ArrowDownIcon />
                </Button>
              )}
              {onDelete && (
                <Button onClick={() => onDelete(row.id)}>
                  <TrashIcon />
                </Button>
              )}
            </div>
          </li>
        ))}
        {!sortedRows.length && <div className={styles.empty}>No Data</div>}
      </ul>
    </div>
  );
}
