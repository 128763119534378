import { Add, Remove } from "@mui/icons-material";
import { IconButton, Input } from "@mui/material";
import { nanoid } from "nanoid";
import React, { useState, useEffect, forwardRef } from "react";
import AccountingItemSelect from "../accounting-item-select";
import styles from "./index.module.css";

type FormItem = {
  id: string;
  accountingItemId: string;
  price: number;
  quantity: number;
  title: string | null;
};

type DraftFormItem = FormItem & { quantityValue: string };

export const generateTempId = () => `temp-${nanoid()}`;

const generateItem = (): DraftFormItem => ({
  id: generateTempId(),
  accountingItemId: "",
  price: 0,
  quantity: 0,
  quantityValue: "0",
  title: "",
});

type Props = {
  value: FormItem[];
  onChange: (items: FormItem[]) => void;
  isCost: boolean;
};

const formItemsToDraft = (items: FormItem[]): DraftFormItem[] => {
  return items.map((item) => ({
    ...item,
    quantityValue: String(item.quantity / 100),
  }));
};

const InvoiceItemCombo = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, isCost }, ref) => {
    const [items, setItems] = useState<DraftFormItem[]>([]);
    useEffect(() => {
      if (value.length === 0) {
        setItems([generateItem()]);
      } else {
        setItems(formItemsToDraft(value));
      }
    }, [value]);

    const handleAccountItemIdChange = (value_: string, id: string) => {
      const newValue = items.map((item) => {
        if (item.id === id) {
          return { ...item, accountingItemId: value_ ?? undefined };
        }

        return item;
      });
      onChange(newValue);
    };

    const handleTitleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      id: string
    ) => {
      event.persist();
      const newValue = items.map((item) => {
        if (item.id === id) {
          return { ...item, title: event.target.value };
        }

        return item;
      });
      onChange(newValue);
    };

    const handleQuantityChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      id: string
    ) => {
      event.persist();
      const originalValue = event.target.value;
      const fixedValue = Number(originalValue.replace(/,/g, ""));
      const isPendingInput = String(fixedValue) !== originalValue;
      const newValue = items.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: Math.floor(fixedValue * 100),
            quantityValue: originalValue,
          };
        }

        return item;
      });
      
      if (isPendingInput) {
        setItems(newValue);
      } else {
        onChange(newValue);
      }
    };

    const handlePriceChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      id: string
    ) => {
      event.persist();
      const fixedValue = Number(event.target.value.replace(/,/g, ""));
      const newValue = items.map((item) => {
        if (item.id === id) {
          return { ...item, price: Math.floor(fixedValue * 100) };
        }

        return item;
      });
      onChange(newValue);
    };

    const handleRemoveItem = (id: string) => {
      onChange(items.filter((item) => item.id !== id));
    };

    const handleAddItem = () => {
      onChange([...items, generateItem()]);
    };

    return (
      <div ref={ref}>
        {items.map((item) => {
          return (
            <div key={item.id} className={styles.line}>
              <div className={styles.item}>
                <AccountingItemSelect
                  isCost={isCost}
                  value={item.accountingItemId}
                  onChange={(value) => {
                    handleAccountItemIdChange(value ?? "", item.id);
                  }}
                />
              </div>
              <Input
                size="small"
                value={item.title}
                placeholder="TITLE"
                onChange={(event) => {
                  handleTitleChange(event, item.id);
                }}
              />
              <Input
                size="small"
                value={item.quantityValue}
                placeholder="QTY."
                sx={{
                  width: "60px",
                  marginRight: "5px",
                  marginLeft: "5px",
                  flexShrink: 0,
                }}
                onChange={(event) => {
                  handleQuantityChange(event, item.id);
                }}
              />
              <Input
                size="small"
                value={item.price / 100}
                placeholder="PR."
                sx={{
                  width: "60px",
                  marginRight: "5px",
                  marginLeft: "5px",
                  flexShrink: 0,
                }}
                onChange={(event) => {
                  handlePriceChange(event, item.id);
                }}
              />
              <div className={styles.sum}>
                ${(item.quantity * item.price) / 10000}
              </div>
              <IconButton
                size="small"
                onClick={() => {
                  handleRemoveItem(item.id);
                }}
              >
                <Remove />
              </IconButton>
            </div>
          );
        })}
        <div>
          <IconButton size="small" onClick={handleAddItem}>
            <Add />
          </IconButton>
        </div>
      </div>
    );
  }
);

export default InvoiceItemCombo;
