import {
  Breadcrumbs,
  ButtonPrimary,
  FormGroup,
  Select,
  TextInput,
} from '@primer/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import styles from './edit-document-column.module.css';
import {
  DocumentColumn,
  documentColumnTypeLabels,
  documentColumnTypes,
} from '../../types/document-column';

const PageEditDocumentColumn = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm<DocumentColumn>();
  const [loading, setLoading] = useState(false);
  const { columnId } = useParams();
  const isCreate = columnId === 'create';
  const { data: column } = useSWR<DocumentColumn>(
    isCreate ? null : `/document-columns/:${columnId}`
  );

  useEffect(() => {
    if (!column) {
      return;
    }

    const { label, type } = column;
    setValue('label', label);
    setValue('type', type);
  }, [column]);

  const onSubmit = async (data: DocumentColumn) => {
    setLoading(true);
    if (isCreate) {
      await axios.post(`/document-columns`, {
        ...data,
        order: 999,
      });
    } else {
      await axios.put(`/document-columns/${columnId}`, data);
    }
    navigate('/settings');
  };

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item href="#" onClick={() => navigate('/settings')}>
          Settings
        </Breadcrumbs.Item>
        <Breadcrumbs.Item href="#" onClick={() => navigate('/settings')}>
          Document Columns
        </Breadcrumbs.Item>
        <Breadcrumbs.Item selected>
          {isCreate ? 'Create' : 'Detail'}
        </Breadcrumbs.Item>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormGroup.Label htmlFor="label">Label*</FormGroup.Label>
          <TextInput id="label" {...register('label', { required: true })} />
        </FormGroup>
        <FormGroup>
          <FormGroup.Label htmlFor="type">Type*</FormGroup.Label>
          <Select
            id="type"
            {...register('type', { required: true })}
          >
            {documentColumnTypes.map((type, index) => (
              <Select.Option key={type} value={type}>
                {documentColumnTypeLabels[index]}
              </Select.Option>
            ))}
          </Select>
        </FormGroup>
        <ButtonPrimary type="submit" disabled={loading}>
          {isCreate ? 'Submit' : 'Save'}
        </ButtonPrimary>
      </form>
    </div>
  );
};

export default PageEditDocumentColumn;
