import { PlusIcon } from "@primer/octicons-react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { Terminal } from "../../types";
import Table from "../../components/table";
import styles from "./style.module.css";
import { Button } from "@mui/material";

const PageTerminals = () => {
  const { data: terminals } = useSWR<Terminal[]>("/terminals");

  const columns = [
    { name: "name" },
    { name: "short" },
    { name: "address" },
    { name: "phone" },
    { name: "email" },
    { name: "contactPerson" },
    {
      name: "id",
      render: (id: string) => (
        <Button size="small">
          <Link to={`/terminals/${id}`}>view</Link>
        </Button>
      ),
    },
  ];

  const title = (
    <div className={styles.title}>
      <div>Terminal</div>
      <div className={styles.actions}>
        <Button>
          <Link to="/terminals/create" className={styles.create}>
            <PlusIcon />
            New Terminal
          </Link>
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Table columns={columns} rows={terminals || []} title={title} />
    </div>
  );
};

export default PageTerminals;
