import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CircularProgress,
  Modal,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import useSWR from "swr";
import { BoxConfig } from "../../types";
import styles from "./index.module.css";

type Props = {
  folder: string;
};

const Box = ({ folder }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isServiceReady, setIsServiceReady] = useState(false);
  const [isLocalFolderReady, setIsLocalFolderReady] = useState(false);
  const [isReleasing, setIsReleasing] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<string>();
  const { data } = useSWR<BoxConfig>("/box/config");

  useEffect(() => {
    if (!isOpen || isServiceReady) {
      return;
    }

    const prepare = async () => {
      if (!window.remote || !data) {
        return;
      }

      try {
        await window.remote.box.prepare(
          ({ percent }) => {
            setProgress(Math.floor(percent * 100));
          },
          data.config,
          data.path
        );

        setIsServiceReady(true);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);

          return;
        }

        throw error;
      }
    };
    void prepare();
  }, [data, isOpen, isServiceReady]);

  useEffect(() => {
    if (!isServiceReady || isLocalFolderReady) {
      return;
    }

    const prepare = async () => {
      if (!window.remote) {
        return;
      }

      try {
        await window.remote.box.openFolder(folder);

        setIsLocalFolderReady(true);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);

          return;
        }

        throw error;
      }
    };
    void prepare();
  }, [folder, isServiceReady, isLocalFolderReady]);

  const handleRelease = async () => {
    if (!window.remote) {
      return;
    }

    setIsReleasing(true);

    try {
      await window.remote.box.releaseFolder();

      setIsReleasing(false);
      setIsOpen(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);

        return;
      }

      throw error;
    }
  };

  if (!window.remote) {
    return null;
  }

  return (
    <>
      <Button
        disabled={!data}
        onClick={() => {
          setIsServiceReady(false);
          setIsLocalFolderReady(false);
          setIsReleasing(false);
          setError(undefined);
          setIsOpen(true);
        }}
      >
        Open Box
      </Button>
      <Modal open={isOpen}>
        <Card className={styles.card}>
          {error ? (
            <>
              <div className={styles.content}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error}
                </Alert>
              </div>
              <div className={styles.footer}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className={styles.content}>
                {!isServiceReady && (
                  <div className={styles.loading}>
                    <div className={styles.icon}>
                      <CircularProgress />
                    </div>
                    <div className={styles.message}>
                      Preparing service... {progress}%
                    </div>
                  </div>
                )}
                {isServiceReady && !isLocalFolderReady && (
                  <div className={styles.loading}>
                    <div className={styles.icon}>
                      <CircularProgress />
                    </div>
                    <div className={styles.message}>Fetching files...</div>
                  </div>
                )}
                {isLocalFolderReady && !isReleasing && (
                  <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    You can now browse files in the Windows's Explorer, after
                    you finish the job with files, click "sync and close"
                    button, and the files will be synced to remote. Notice that
                    after you close this dialog, the local changes will no
                    longer sync to remote.
                  </Alert>
                )}
                {isReleasing && (
                  <div className={styles.loading}>
                    <div className={styles.icon}>
                      <CircularProgress />
                    </div>
                    <div className={styles.message}>Uploading files...</div>
                  </div>
                )}
              </div>
              <div className={styles.footer}>
                {isLocalFolderReady ? (
                  <Button
                    variant="contained"
                    onClick={handleRelease}
                    disabled={isReleasing}
                  >
                    Sync and close
                  </Button>
                ) : (
                  <Button variant="contained" disabled>
                    Close
                  </Button>
                )}
              </div>
            </>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default Box;
