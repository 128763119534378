import { Button } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { wrapInvoiceSerial } from "shared";
import useSWR from "swr";
import CustomerSelect from "../../../../components/customer-select";
import InvoiceItemCombo, {
  generateTempId,
} from "../../../../components/invoice-item-combo";
import VendorSelect from "../../../../components/vendor-select";
import useTenant from "../../../../swr/use-tenant";
import {
  Customer,
  Invoice,
  InvoiceItem,
  Order,
  Vendor,
} from "../../../../types";
import { renderPrice, renderQuantity } from "../../../../utils/price";
import styles from "./invoice-form.module.css";

type Props = {
  invoice: Invoice;
  mutate: () => void;
  orderId: string;
};

type InvoiceForm = {
  customerId: Invoice["customerId"];
  vendorId: Invoice["vendorId"];
  items: Array<
    Pick<
      InvoiceItem,
      "id" | "accountingItemId" | "price" | "quantity" | "title"
    >
  >;
};

const InvoiceForm = ({ invoice, orderId, mutate }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
    setValue,
  } = useForm<InvoiceForm>({ defaultValues: { items: [] } });
  const { enqueueSnackbar } = useSnackbar();
  const customerId = watch("customerId");
  const vendorId = watch("vendorId");
  const items = watch("items");
  const [printLoading, setPrintLoading] = useState(false);
  const tenant = useTenant();
  const { data: customer } = useSWR<Customer>(
    customerId ? `/customers/${customerId}` : null
  );
  const { data: vendor } = useSWR<Vendor>(
    vendorId ? `/vendors/${vendorId}` : null
  );
  const { data: order } = useSWR<Order>(`/orders/${orderId}`);

  const sum = useMemo(() => {
    return items.reduce(
      (sum, item) => sum + (item.quantity / 100) * item.price,
      0
    );
  }, [items]);

  useEffect(() => {
    reset({
      items: invoice.items ?? [],
      customerId: invoice.customerId ?? null,
      vendorId: invoice.vendorId ?? null,
    });
  }, [invoice]);

  const onSubmit = async (data: InvoiceForm) => {
    await axios.put(`/orders/${orderId}/invoices/${invoice.id}`, {
      ...data,
      items: data.items.map((item) => {
        if (item.id.startsWith("temp-")) {
          const { id, ...others } = item;
          return others;
        }

        return item;
      }),
    });
    mutate();
    enqueueSnackbar("Saved", { variant: "success" });
  };

  const handleAutoFill = () => {
    if (!customer && !vendor) {
      return;
    }

    setValue(
      "items",
      (customer?.invoiceItems ?? vendor?.invoiceItems ?? []).map((item) => ({
        id: generateTempId(),
        accountingItemId: item.accountingItemId,
        price: item.price,
        quantity: item.quantity,
        title: item.title,
      }))
    );
  };

  const handlePrint = async () => {
    if (!window.remote || !tenant || !order) {
      return;
    }

    setPrintLoading(true);
    const data = {
      serial: wrapInvoiceSerial(tenant.short, invoice.number),
      mbl: order.mbl,
      cntr: order.cntr,
      ref: order.ref,
    };
    const billingInfo = customer ?? vendor;
    const billing = {
      title: invoice.isCost ? "VENDOR" : "BILL TO",
      content: billingInfo
        ? `${billingInfo.name}, ${billingInfo.email}, ${billingInfo.address}`
        : "",
    };
    const items = invoice.items.map((item) => ({
      description: `${item.accountingItem?.number} ${
        item.accountingItem?.title
      } ${item.title ?? ""}`,
      quantity: renderQuantity(item.quantity),
      rate: renderPrice(item.price),
      amount: renderPrice((item.quantity / 100) * item.price),
    }));
    const total = {
      description: "TOTAL",
      quantity: "",
      rate: "",
      amount: renderPrice(
        invoice.items.reduce(
          (sum, item) => sum + (item.quantity / 100) * item.price,
          0
        )
      ),
    };
    const branding =
      tenant.invoiceLogo || tenant.invoiceTitle
        ? {
            logo: tenant.invoiceLogo ?? "",
            title: tenant.invoiceTitle ?? "",
          }
        : undefined;

    try {
      await window.remote.printInvoice(
        data.serial,
        data,
        billing,
        [...items, total],
        branding
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        alert(e.message);
      }
    }

    setPrintLoading(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.customerVendor}>
          <div className={styles.select}>
            {invoice.isCost ? (
              <Controller
                control={control}
                name="vendorId"
                render={({ field }) => <VendorSelect {...field} />}
              />
            ) : (
              <Controller
                control={control}
                name="customerId"
                render={({ field }) => <CustomerSelect {...field} />}
              />
            )}
          </div>
          <Button onClick={handleAutoFill} disabled={!customer && !vendor}>
            FILL
          </Button>
        </div>
        <div className={styles.items}>
          <Controller
            control={control}
            name="items"
            render={({ field }) => (
              <InvoiceItemCombo isCost={invoice.isCost} {...field} />
            )}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.sum}>${sum / 100}</div>
          {window.remote && (
            <Button onClick={handlePrint} disabled={printLoading}>
              Print
            </Button>
          )}
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InvoiceForm;
