.line {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.item {
  min-width: 300px;
  margin-right: 8px;
}

.sum {
  margin: 0 8px;
  flex: 1;
}
