import { Button } from '@mui/material';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import Loading from '../../components/loading';
import Table from '../../components/table';
import { Profile } from '../../types';
import Detail from './detail';
import styles from './index.module.css';

const PageUsers = () => {
  const { data: users, error, mutate } = useSWR<Profile[]>('/users');
  const [addVisible, setAddVisible] = useState(false);
  const [userId, setUserId] = useState<string>();

  const handleClickNew = () => {
    setAddVisible(true);
  };

  const columns = [
    {
      name: 'name',
      flex: true,
    },
    {
      name: 'email',
    },
    {
      name: 'role',
      width: 100,
    },
    {
      name: 'action',
      render: (_: unknown, { id }: Profile) => (
        <Button
          onClick={() => {
            setUserId(id);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const rows = useMemo(() => {
    if (!users) {
      return [];
    }

    return users;
  }, [users]);

  const loading = !users && !error;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <h1>Manage Users</h1>
      <div>
        <Button variant="contained" onClick={handleClickNew}>
          New user
        </Button>
      </div>
      <Table rows={rows} title="Users" columns={columns} />
      <Detail
        visible={addVisible}
        onClose={() => {
          setAddVisible(false);
          mutate();
        }}
      />
      <Detail
        visible={Boolean(userId)}
        userId={userId}
        onClose={() => {
          setUserId(undefined);
          mutate();
        }}
      />
    </div>
  );
};

export default PageUsers;
