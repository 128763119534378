import { Breadcrumbs } from "@primer/react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { AccountingItem } from "../../types";
import { Button, MenuItem, TextField } from "@mui/material";
import styles from "./create.module.css";

type Form = {
  number: string;
  title: string;
  type: string;
  sellCostType: "Sell" | "Cost";
};

const PageCreateAccountingItem = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm<Form>({
    defaultValues: {
      sellCostType: "Sell",
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: Form) => {
    setLoading(true);
    const { sellCostType, ...others } = data;
    const {
      data: { id },
    } = await axios.post<AccountingItem>(`/accounting-items`, {
      ...others,
      isCost: sellCostType === "Cost",
    });
    navigate(`/accounting-items/${id}`);
  };

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item
          href="#"
          onClick={() => navigate("/accounting-items")}
        >
          Accounting
        </Breadcrumbs.Item>
        <Breadcrumbs.Item selected>Create</Breadcrumbs.Item>
      </Breadcrumbs>
      <div className={styles.form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formField}>
            <TextField
              fullWidth
              id="number"
              label="Code"
              required
              {...register("number", { required: true })}
            />
          </div>
          <div className={styles.formField}>
            <TextField
              fullWidth
              id="title"
              label="Title"
              required
              {...register("title", { required: true })}
            />
          </div>
          <div className={styles.formField}>
            <TextField
              fullWidth
              id="type"
              label="Custom Type"
              {...register("type")}
            />
          </div>
          <div className={styles.formField}>
            <Controller
              control={control}
              name="sellCostType"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  select
                  id="sellCostType"
                  label="Type"
                  required
                  {...field}
                >
                  <MenuItem value="Cost">Cost</MenuItem>
                  <MenuItem value="Sell">Sell</MenuItem>
                </TextField>
              )}
            />
          </div>
          <Button variant="contained" type="submit" disabled={loading}>
            Create
          </Button>
        </form>
      </div>
    </div>
  );
};

export default PageCreateAccountingItem;
