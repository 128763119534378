import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { wrapOrderSerial } from "shared";
import useSWR from "swr";
import useTenant from "../../../swr/use-tenant";
import { Order } from "../../../types";

type Props = {
  orderId: string;
};

const OrderBreadcrumbs = ({ orderId }: Props) => {
  const tenant = useTenant();
  const { data } = useSWR<Order>(orderId ? `/orders/${orderId}` : null);

  if (!data || !tenant) {
    return null;
  }

  return (
    <Breadcrumbs>
      <Link to="/orders">Orders</Link>
      <span>{wrapOrderSerial(tenant.short, data.number)}</span>
    </Breadcrumbs>
  );
};

export default OrderBreadcrumbs;
