import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./index.module.css";

type Props = {
  label: string;
  children: ReactNode;
  size?: "medium" | "large" | "xlarge";
  color?: "default" | "red" | "green";
};

const FormGroup = ({
  label,
  children,
  size = "medium",
  color = "default",
}: Props) => {
  return (
    <div className={classNames(styles.group, styles[color], styles[size])}>
      <label>{label}</label>
      <div className={styles.control}>{children}</div>
    </div>
  );
};

export default FormGroup;
