import { Button, Input } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useSWR from "swr";
import FormGroup from "../../components/form-group";
import ImageUpload from "../../components/image-upload";
import { Tenant } from "../../types";
import styles from "./tenant-info.module.css";

type Form = {
  photo: string;
  invoiceLogo: string;
  invoiceTitle: string;
};

const TenantInfo = () => {
  const { data, mutate } = useSWR<Tenant>("/tenant");
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { isSubmitting },
  } = useForm<Form>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!data) {
      return;
    }

    const { photo, invoiceLogo, invoiceTitle } = data;
    setValue("photo", photo);
    setValue("invoiceLogo", invoiceLogo ?? "");
    setValue("invoiceTitle", invoiceTitle ?? "");
  }, [data]);

  const onSubmit = async (data: Form) => {
    await axios.put("/tenant", data);
    await mutate();
    enqueueSnackbar("Saved", { variant: "success" });
  };

  return (
    <div>
      {data && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.fields}>
            <FormGroup label="Logo" size="large">
              <Controller
                control={control}
                name="photo"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <ImageUpload value={value} onChange={onChange} />
                )}
              />
            </FormGroup>
            <FormGroup label="Invoice Logo" size="large">
              <Controller
                control={control}
                name="invoiceLogo"
                render={({ field: { value, onChange } }) => (
                  <ImageUpload value={value} onChange={onChange} />
                )}
              />
            </FormGroup>
            <FormGroup label="Invoice Title" size="large">
              <Input fullWidth multiline {...register("invoiceTitle")} />
            </FormGroup>
          </div>
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </form>
      )}
    </div>
  );
};

export default TenantInfo;
