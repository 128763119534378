import { Profile } from '../types';

type TokenPayload = {
  sub: string;
  email: string;
  name: string;
  picture: string;
  tenantId: string;
  role: string;
};

export const getProfile = (): Profile | null => {
  const token = localStorage.getItem('shark-token');

  if (!token) {
    return null;
  }

  const { 1: encodedPayload } = token.split('.');

  if (!encodedPayload) {
    return null;
  }

  try {
    const json = JSON.parse(window.atob(encodedPayload)) as TokenPayload;
    const { sub, ...rest } = json;

    return {
      id: sub,
      ...rest,
    };
  } catch {
    return null;
  }
};
