import { Chip } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { OrderContainerForm } from "../../types";

type Props = {
  caseDoneDate?: string;
};

const TBillingDays = ({ caseDoneDate }: Props) => {
  const { watch } = useFormContext<OrderContainerForm>();
  const emptyReturnDate = watch("emptyReturnDate");

  const dayCount = useMemo(() => {
    if (!emptyReturnDate) {
      return 0;
    }

    const erMoment = moment(emptyReturnDate);

    if (!erMoment.isValid()) {
      return 0;
    }

    const caseDoneMoment = caseDoneDate ? moment(caseDoneDate) : moment();
    const startUnix = Math.min(
      moment().startOf("day").unix(),
      caseDoneMoment.startOf("day").unix()
    );

    const seconds = startUnix - erMoment.startOf("day").unix();

    if (seconds < 0) {
      return 0;
    }

    return Math.ceil(seconds / 86_400) + 1;
  }, [caseDoneDate, emptyReturnDate]);

  return (
    <Chip
      size="small"
      sx={{ width: "100%" }}
      label={`T-BILLING DAYS: ${dayCount === 0 ? "-" : dayCount}`}
      color={dayCount >= 5 && !caseDoneDate ? "error" : "default"}
    />
  );
};

export default TBillingDays;
