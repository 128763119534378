.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: baseline;
}

.field {
  width: 280px;
}

.field.large {
  width: 560px;
}

.field .label {
  width: 80px;
  font-size: 14px;
  font-weight: 600;
}

.field .control {
  flex: 1;
}

.eta {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actions {
  margin-top: 16px;
}

.actions > button {
  margin-right: 8px;
}
