export const queryStringify = (
  input: Record<string, string | number | null | string[]>
): string => {
  const values = Object.entries(input).reduce<string[]>(
    (prev, [key, value]) => {
      if (!value) {
        return prev;
      }

      if (Array.isArray(value)) {
        return [...prev, value.map((item) => `&${key}=${item}`).join("")];
      }

      return [...prev, `&${key}=${value}`];
    },
    []
  );

  return values.join('');
};
