.group {
  flex: 1;
}

.group:first-child {
  margin-right: 8px;
}

.group:last-child {
  margin-left: 8px;
}

.tabs > * > * {
  flex-wrap: wrap;
}

.tabs .tabIndicator {
  background: none;
}

.content {
  position: relative;
  min-height: 400px;
}

.fab {
  position: absolute;
  left: 16px;
  bottom: 16px;
}
