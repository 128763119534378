.title {
  flex: 1;
  display: flex;
  align-items: center;
}
.actions {
  flex: 1;
  margin-left: 8px;
  justify-content: flex-end;
  display: flex;
}
.create {
  text-decoration: none;
  color: unset;
}
