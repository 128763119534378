import { MenuItem, Select } from "@mui/material";
import { useMemo } from "react";
import { useConstants } from "../../../../swr/use-constants";

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
};

const StatusFilter = ({ value, onChange }: Props) => {
  const constants = useConstants();

  const options = useMemo(() => {
    const allOption = { value: "all", label: "ALL STATUS" };

    if (!constants) {
      return [];
    }

    return [
      allOption,
      ...constants.statusEnums.map((value) => ({ value, label: value })),
    ];
  }, [constants]);

  const selectValue = value.length > 0 ? value : ["all"];

  return (
    <Select
      multiple
      fullWidth
      value={selectValue}
      variant="standard"
      onChange={(event) => {
        const values = event.target.value as string[];

        if (values.length > 1 && values.includes("all")) {
          if (value.length > 0) {
            onChange([]);
          } else {
            onChange(values.filter((value_) => value_ !== "all"));
          }
        } else {
          onChange(values);
        }
      }}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StatusFilter;
