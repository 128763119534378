.label {
  position: relative;
}

.nextLabel {
  position: absolute;
  right: 0;
  top: -10px;
  white-space: nowrap;
}

.nextLabel.last {
  right: unset;
  left: 50%;
  padding-left: 20px;
}

.content {
  border-left: none !important;
  font-size: 12px;
  display: flex;
  justify-content: center;
  gap: 8px;
}
