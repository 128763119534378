import { PlusIcon } from '@primer/octicons-react';
import { Button } from '@primer/react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import Table from '../../components/table';
import {
  DocumentColumn,
  DocumentColumnType,
  documentColumnTypeLabels,
  documentColumnTypes,
} from '../../types/document-column';
import styles from './document-columns.module.css';

export default function DocumentColumnsPage() {
  const { data: columns, mutate } =
    useSWR<DocumentColumn[]>('/document-columns');

  const handleDelete = async (id: string) => {
    await axios.delete(`/document-columns/${id}`);
    mutate();
  };

  const handleSort = async (id: string, targetId: string) => {
    const row = columns?.find((c) => c.id === id);
    const targetRow = columns?.find((c) => c.id === targetId);
    if (!row || !targetRow) {
      throw new Error('Faild to sort');
    }
    await axios.put(`/document-columns/${id}`, {
      ...row,
      order: targetRow.order,
    });
    await axios.put(`/document-columns/${targetId}`, {
      ...targetRow,
      order: row.order,
    });
    mutate();
  };

  if (!columns) {
    return null;
  }

  const tableColumns = [
    {
      name: 'label',
      width: 200,
      render: (value: string, { id }: { id: string }) => (
        <Link to={`/settings/document-columns/${id}`}>{value}</Link>
      ),
    },
    {
      name: 'type',
      width: 200,
      render: (value: DocumentColumnType) => {
        const index = documentColumnTypes.indexOf(value);
        if (index === -1) {
          return '-';
        }
        return documentColumnTypeLabels[index];
      },
    },
  ];

  const title = (
    <div className={styles.title}>
      <div>Document Columns</div>
      <div className={styles.actions}>
        <Button>
          <Link
            to="/settings/document-columns/create"
            className={styles.create}
          >
            <PlusIcon />
            New Column
          </Link>
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Table
        columns={tableColumns}
        rows={columns}
        title={title}
        sortKey="order"
        onSort={handleSort}
        onDelete={handleDelete}
      />
    </div>
  );
}
