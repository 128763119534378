.header {
  background: #f6f8fa;
  border: 1px solid #d0d7de;
  border-radius: 6px 6px 0 0;
  margin-bottom: -1px;
  margin-top: 20px;
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.list {
  width: 100%;
  padding: 0;
  margin: 0;
}

.list > li {
  list-style: none;
  display: flex;
  border-bottom: 1px solid #d0d7de;
  border-left: 1px solid #d0d7de;
  border-right: 1px solid #d0d7de;
  margin-left: 0;
  padding: 16px 0 16px 16px;
  align-items: center;
}

.list > li:last-child {
  border-radius: 0 0 6px 6px;
}

.empty {
  border-bottom: 1px solid #d0d7de;
  border-left: 1px solid #d0d7de;
  border-right: 1px solid #d0d7de;
  border-radius: 0 0 6px 6px;
  text-align: center;
  color: #d0d7de;
  padding: 16px;
}

.actions button {
  margin-right: 10px;
}

.cell {
  margin-right: 16px;
}

.flex {
  flex: 1;
}
