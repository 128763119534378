import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import CustomerSelect from "../../components/customer-select";
import Driver from "../../components/driver";
import DriverSelect from "../../components/driver-select";
import Notes from "../../components/notes";
import { Order } from "../../types/order";
import styles from "./order.module.css";
import SslSelect from "../../components/ssl-select";
import { OrderForm } from "./types";
import DepotSelect from "../../components/depot-select";
import TerminalSelect from "../../components/terminal-select";
import AddressSelect from "../../components/address-select";
import StatusBar from "../../components/status-bar";
import { useSnackbar } from "notistack";
import { Button, Input } from "@mui/material";
import OrderDetailNavs from "./components/order-detail-navs";
import OrderBreadcrumbs from "./components/order-breadcrumbs";
import {
  localInputDateToRemote,
  remoteDateToLocalInput,
} from "../../utils/date-input";
import FormGroup from "../../components/form-group";
import Available from "./components/available";
import Box from "../../components/box";
import useTenant from "../../swr/use-tenant";
import { useMemo } from "react";
import { wrapOrderSerial } from "shared";
import TimeZone from "./components/time-zone";
import { useConstants } from "../../swr/use-constants";
import useProfile from "../../hooks/use-profile";
import { isUserAdmin } from "../../utils/is-user-admin";

const PageOrder = () => {
  const { orderId } = useParams();
  const { data: order, mutate } = useSWR<Order>(
    orderId ? `/orders/${orderId}` : null
  );
  const { handleSubmit, control, setValue, watch } = useForm<OrderForm>();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useTenant();
  const constants = useConstants();
  const navigate = useNavigate();
  const profile = useProfile();
  const isAdmin = profile && isUserAdmin(profile);

  const serial = useMemo(() => {
    if (!order || !tenant) {
      return;
    }

    return wrapOrderSerial(tenant.short, order.number);
  }, [order, tenant]);

  const cntr = watch("cntr");

  const caseDoneDate = useMemo(() => {
    if (!order || !constants) {
      return;
    }

    const statusItem = order.statusLogs.find(
      (log) =>
        log.status === constants.statusEnums[constants.statusEnums.length - 2]
    );

    return statusItem?.createdAt;
  }, [order]);

  useEffect(() => {
    if (!order) {
      return;
    }

    const {
      mbl,
      cntr,
      ref,
      etaToPort,
      etaDischarged,
      appointment,
      note,
      cfHold,
      size,
      available,
      customerId,
      billingId,
      sslId,
      addressId,
      terminalId,
      depotId,
      status,
      driverUserId,
      containers,
    } = order;
    setValue("mbl", mbl);
    setValue("cntr", cntr);
    setValue("ref", ref);
    setValue("etaToPort", remoteDateToLocalInput(etaToPort));
    setValue("etaDischarged", remoteDateToLocalInput(etaDischarged));
    setValue("appointment", appointment);
    setValue("note", note);
    setValue("cfHold", cfHold);
    setValue("size", size);
    setValue("available", available);
    setValue("customerId", customerId);
    setValue("billingId", billingId);
    setValue("sslId", sslId);
    setValue("addressId", addressId);
    setValue("terminalId", terminalId);
    setValue("depotId", depotId);
    setValue("status", status);
    setValue("driverUserId", driverUserId);
    setValue(
      "containers",
      containers.map(
        ({ cntr, lfd, pickupDate, emptyReturnDate, deliverDate }) => ({
          cntr,
          lfd: remoteDateToLocalInput(lfd),
          pickupDate: remoteDateToLocalInput(pickupDate),
          emptyReturnDate: remoteDateToLocalInput(emptyReturnDate),
          deliverDate: remoteDateToLocalInput(deliverDate),
        })
      )
    );
  }, [order]);

  const onSubmit = async (data: OrderForm) => {
    if (!order) {
      return;
    }

    setLoading(true);
    try {
      const { status, etaToPort, etaDischarged, containers, ...others } = data;
      if (status !== order.status) {
        await axios.patch(`/orders/${orderId}/status`, { status });
      }
      await axios.put(`/orders/${orderId}`, {
        ...others,
        etaToPort: localInputDateToRemote(etaToPort),
        etaDischarged: localInputDateToRemote(etaDischarged),
        containers: containers.map(
          ({ lfd, deliverDate, pickupDate, emptyReturnDate, ...rest }) => ({
            ...rest,
            lfd: localInputDateToRemote(lfd),
            deliverDate: localInputDateToRemote(deliverDate),
            pickupDate: localInputDateToRemote(pickupDate),
            emptyReturnDate: localInputDateToRemote(emptyReturnDate),
          })
        ),
      });
      await mutate();
      enqueueSnackbar("SAVED", { variant: "success" });
    } catch {
      enqueueSnackbar("Submit failed", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (
      !confirm(
        `Deleting order is permament and can not be undone, are you sure to delete?`
      )
    ) {
      return;
    }

    await axios.delete(`/orders/${orderId}`);
    enqueueSnackbar("DELETED", { variant: "success" });
    navigate("/orders");
  };

  if (!orderId) {
    return null;
  }

  return (
    <div>
      <OrderBreadcrumbs orderId={orderId} />
      <div style={{ marginBottom: "16px" }} />
      <OrderDetailNavs current="order" orderId={orderId} />
      {order && (
        <div className={styles.page}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.status}>
              <Controller
                name="status"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StatusBar
                    value={value}
                    onChange={onChange}
                    statusLogs={order.statusLogs}
                  />
                )}
              />
            </div>
            <div className={styles.formItems}>
              <FormGroup label="MBL">
                <Controller
                  name="mbl"
                  control={control}
                  render={({ field }) => <Input fullWidth {...field} />}
                />
              </FormGroup>
              <FormGroup label="CNTR">
                <Controller
                  name="cntr"
                  control={control}
                  render={({ field }) => <Input fullWidth {...field} />}
                />
              </FormGroup>
              <FormGroup label="REF">
                <Controller
                  name="ref"
                  control={control}
                  render={({ field }) => <Input fullWidth {...field} />}
                />
              </FormGroup>
              <FormGroup label="SIZE">
                <Controller
                  name="size"
                  control={control}
                  render={({ field }) => <Input fullWidth {...field} />}
                />
              </FormGroup>
              <Controller
                name="available"
                control={control}
                render={({ field }) => <Available {...field} />}
              />
              <FormGroup label="Customer">
                <Controller
                  name="customerId"
                  control={control}
                  render={({ field }) => <CustomerSelect {...field} />}
                />
              </FormGroup>
              <FormGroup label="Driver">
                <Controller
                  name="driverUserId"
                  control={control}
                  render={({ field }) => <DriverSelect {...field} />}
                />
              </FormGroup>
              <FormGroup label="Billing">
                <Controller
                  name="billingId"
                  control={control}
                  render={({ field }) => <CustomerSelect {...field} />}
                />
              </FormGroup>
              <FormGroup label="SSL">
                <Controller
                  name="sslId"
                  control={control}
                  render={({ field }) => <SslSelect {...field} />}
                />
              </FormGroup>
              <FormGroup label="Depot">
                <Controller
                  name="depotId"
                  control={control}
                  render={({ field }) => <DepotSelect {...field} />}
                />
              </FormGroup>
              <FormGroup label="Terminal">
                <Controller
                  name="terminalId"
                  control={control}
                  render={({ field }) => <TerminalSelect {...field} />}
                />
              </FormGroup>
              <FormGroup label="Address">
                <Controller
                  name="addressId"
                  control={control}
                  render={({ field }) => <AddressSelect {...field} />}
                />
              </FormGroup>
              <FormGroup label="ETA to port">
                <Controller
                  name="etaToPort"
                  control={control}
                  render={({ field }) => (
                    <Input fullWidth type="date" {...field} />
                  )}
                />
              </FormGroup>
              <FormGroup label="ETA discharged">
                <Controller
                  name="etaDischarged"
                  control={control}
                  render={({ field }) => (
                    <Input fullWidth type="date" {...field} />
                  )}
                />
              </FormGroup>
              <FormGroup label="Appointment">
                <Controller
                  name="appointment"
                  control={control}
                  render={({ field }) => <Input fullWidth {...field} />}
                />
              </FormGroup>
              <Controller
                name="containers"
                control={control}
                render={({ field }) => (
                  <TimeZone
                    cntr={cntr}
                    caseDoneDate={caseDoneDate}
                    {...field}
                  />
                )}
              />
              <FormGroup label="Note">
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <Input multiline fullWidth rows={12} {...field} />
                  )}
                />
              </FormGroup>
            </div>
            <div className={styles.actions}>
              <Button variant="contained" type="submit" disabled={loading}>
                Save Changes
              </Button>
              {serial && <Box folder={serial} />}
            </div>
          </form>
          {orderId && <Notes orderId={orderId} />}
          <div className={styles.meta}>
            Order created at{" "}
            {moment(order.createdAt).format("YYYY-MM-DD HH:mm")} by{" "}
            <Driver userId={order.createUserId} />.
          </div>
          {isAdmin && (
            <div className={styles.dangerZone}>
              <div className={styles.label}>
                Delete this order will permanently delete all infomations,
                containers, documents, invoices, box files associated with this
                project.
              </div>
              <div className={styles.action}>
                <Button variant="outlined" onClick={handleDelete}>
                  Delete Order
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PageOrder;
