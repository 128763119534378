import { Breadcrumbs } from "@primer/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { Button, Input } from "@mui/material";
import { Customer } from "../../types";
import FormGroup from "../../components/form-group";
import styles from './create.module.css';

const PageCreateCustomer = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: unknown) => {
    setLoading(true);
    const {
      data: { id },
    } = await axios.post<Customer>(`/customers`, data);
    navigate(`/customers/${id}`);
  };

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item href="#" onClick={() => navigate("/customers")}>
          Customers
        </Breadcrumbs.Item>
        <Breadcrumbs.Item selected>Create</Breadcrumbs.Item>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          <FormGroup label="Name">
            <Input
              fullWidth
              id="name"
              {...register("name", { required: true })}
            />
          </FormGroup>
          <FormGroup label="Code">
            <Input
              fullWidth
              id="code"
              {...register("code", { required: true })}
            />
          </FormGroup>
          <FormGroup label="Address">
            <Input fullWidth multiline id="address" {...register("address")} />
          </FormGroup>
          <FormGroup label="Email">
            <Input fullWidth id="email" {...register("email")} />
          </FormGroup>
          <FormGroup label="Phone">
            <Input fullWidth id="phone" {...register("phone")} />
          </FormGroup>
          <FormGroup label="Contact">
            <Input fullWidth multiline id="contact" {...register("contact")} />
          </FormGroup>
        </div>
        <Button variant="contained" type="submit" disabled={loading}>
          Create
        </Button>
      </form>
    </div>
  );
};

export default PageCreateCustomer;
