.customerVendor {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.customerVendor .select {
  flex: 1;
}

.footer {
  margin-top: 16px;
  display: flex;
  justify-content: right;
  gap: 16px;
  align-items: center;
}
