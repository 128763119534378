.wrapper {
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 16px;
}

.image {
  width: 100px;
  height: auto;
}

.input {
  display: none;
}
