import { TabNav } from "@primer/react";
import axios from "axios";
import { useNavigate } from "react-router";
import useSWR from "swr";
import { Document } from "../../../types/document";

type Props = {
  current: "order" | "document" | "invoice";
  orderId: string;
};

const OrderDetailNavs = ({ orderId, current }: Props) => {
  const { data: documents } = useSWR<Document[]>(
    `/orders/${orderId}/documents`
  );

  const navigate = useNavigate();

  const handleGoToDocument = async () => {
    if (!documents?.length) {
      const {
        data: { id },
      } = await axios.post<Document>(`/orders/${orderId}/documents`, {
        data: {},
      });
      navigate(`/orders/${orderId}/documents/${id}`);
      return;
    }

    navigate(`/orders/${orderId}/documents/${documents[0].id}`);
  };

  const handleGoToInvoice = async () => {
    navigate(`/orders/${orderId}/invoices`);
  };

  const handleGoToOrder = async () => {
    navigate(`/orders/${orderId}`);
  };

  return (
    <TabNav>
      <TabNav.Link
        href="#"
        selected={current === "order"}
        onClick={handleGoToOrder}
      >
        Order
      </TabNav.Link>
      <TabNav.Link
        href="#"
        onClick={handleGoToInvoice}
        selected={current === "invoice"}
      >
        Invoice
      </TabNav.Link>
      <TabNav.Link
        href="#"
        onClick={handleGoToDocument}
        selected={current === "document"}
      >
        Document
      </TabNav.Link>
    </TabNav>
  );
};

export default OrderDetailNavs;
