export const renderPrice = (number: number) => `$${(number / 100).toFixed(2)}`;

export const renderQuantity = (number: number) => {
  const hasPoint = number % 100 > 0;

  if (hasPoint) {
    return (number / 100).toFixed(2);
  }

  return String(Math.floor(number / 100));
};
