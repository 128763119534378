.operations {
  margin-bottom: 16px;
}
.create {
  text-decoration: none;
  color: unset;
}
.space {
  margin-bottom: 16px;
}
.table {
  flex: 1;
}
