import { Settings } from "@mui/icons-material";
import { Button, TablePagination } from "@mui/material";
import { useState } from "react";
import { ChangeEvent, ReactNode } from "react";
import Config from "./config";

import styles from "./footer.module.css";
import { DataTableColumn, DataTableColumnConfig } from "./types";

type Props = {
  footer?: ReactNode;
  count: number;
  current: number;
  pageSize: number;
  isLoading: boolean;
  allColumns?: DataTableColumn[];
  configColumns?: DataTableColumnConfig[];
  onConfigColumnsChange?: (value: DataTableColumnConfig[]) => void;
  onPageChange: (value: number) => void;
  onPageSizeChange: (value: number) => void;
};

const Footer = ({
  footer,
  count,
  current,
  pageSize,
  isLoading,
  onPageChange,
  onPageSizeChange,
  allColumns,
  configColumns,
  onConfigColumnsChange,
}: Props) => {
  const [configOpen, setConfigOpen] = useState(false);

  const handlePageChange = (_: unknown, number: number) => {
    onPageChange(number);
  };

  const handleSizeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(Number(event.target.value));
    onPageChange(0);
  };

  return (
    <div className={styles.footer}>
      <div className={styles.actions}>
        <Button
          onClick={() => {
            setConfigOpen(true);
          }}
        >
          <Settings />
        </Button>
        {footer}
      </div>
      <TablePagination
        size="small"
        component="div"
        className={styles.pagination}
        count={count}
        page={current}
        rowsPerPage={pageSize}
        labelRowsPerPage="PAGE SIZE"
        backIconButtonProps={{ size: "small" }}
        nextIconButtonProps={{ size: "small" }}
        SelectProps={{ size: "small", native: true, disabled: isLoading }}
        onRowsPerPageChange={handleSizeChange}
        onPageChange={handlePageChange}
      />
      {allColumns && configColumns && onConfigColumnsChange && (
        <Config
          visible={configOpen}
          onClose={() => {
            setConfigOpen(false);
          }}
          source={allColumns}
          value={configColumns}
          onChange={onConfigColumnsChange}
        />
      )}
    </div>
  );
};

export default Footer;
