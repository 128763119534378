.container {
  width: 580px;
}

.line {
  display: flex;
  align-items: center;
  gap: 16px;
}

.line > *:last-child {
  width: 200px;
}
