import { Button, Card, CardContent, Input } from "@mui/material";
import classNames from "classnames";
import { useRef } from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import AddressSelect from "../../../components/address-select";
import CustomerSelect from "../../../components/customer-select";
import DepotSelect from "../../../components/depot-select";
import SslSelect from "../../../components/ssl-select";
import TerminalSelect from "../../../components/terminal-select";
import styles from "./filter.module.css";
import StatusFilter from "./status-filter";

export type OrderFilterData = {
  search: string;
  customerId: string;
  billingId: string;
  driverUserId: string;
  sslId: string;
  terminalId: string;
  addressId: string;
  depotId: string;
  etaToPortStart: string;
  etaToPortEnd: string;
  status: string[];
};

type Props = {
  value: Partial<OrderFilterData>;
  onChange: (value: OrderFilterData) => void;
};

const OrderFilter = ({ value, onChange }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<OrderFilterData>({ defaultValues: { status: [] } });
  const isDirtyRef = useRef(false);

  useEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  useEffect(() => {
    reset({
      search: value.search ?? "",
      customerId: value.customerId ?? "",
      billingId: value.billingId ?? "",
      driverUserId: value.driverUserId ?? "",
      sslId: value.sslId ?? "",
      terminalId: value.terminalId ?? "",
      addressId: value.addressId ?? "",
      depotId: value.depotId ?? "",
      etaToPortStart: value.etaToPortStart ?? "",
      etaToPortEnd: value.etaToPortEnd ?? "",
      status: value.status ?? [],
    });
  }, [value]);

  const handleReset = () => {
    const emptyValue = {
      search: "",
      customerId: "",
      billingId: "",
      driverUserId: "",
      sslId: "",
      terminalId: "",
      addressId: "",
      depotId: "",
      etaToPortStart: "",
      etaToPortEnd: "",
      status: [],
    };
    reset(emptyValue);
    onChange(emptyValue);
  };

  const onSubmit = (data: OrderFilterData) => {
    onChange(data);
  };

  return (
    <div className={styles.filter}>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <div className={styles.label}>Search</div>
                <div className={styles.control}>
                  <Input fullWidth {...register("search")} />
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Customer</div>
                <div className={styles.control}>
                  <Controller
                    name="customerId"
                    control={control}
                    render={({ field }) => <CustomerSelect {...field} />}
                  />
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Billing</div>
                <div className={styles.control}>
                  <Controller
                    name="billingId"
                    control={control}
                    render={({ field }) => <CustomerSelect {...field} />}
                  />
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Address</div>
                <div className={styles.control}>
                  <Controller
                    name="addressId"
                    control={control}
                    render={({ field }) => <AddressSelect {...field} />}
                  />
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>SSL</div>
                <div className={styles.control}>
                  <Controller
                    name="sslId"
                    control={control}
                    render={({ field }) => <SslSelect {...field} />}
                  />
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Terminal</div>
                <div className={styles.control}>
                  <Controller
                    name="terminalId"
                    control={control}
                    render={({ field }) => <TerminalSelect {...field} />}
                  />
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Depot</div>
                <div className={styles.control}>
                  <Controller
                    name="depotId"
                    control={control}
                    render={({ field }) => <DepotSelect {...field} />}
                  />
                </div>
              </div>
              <div className={classNames(styles.field, styles.large)}>
                <div className={styles.label}>Status</div>
                <div className={styles.control}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => <StatusFilter {...field} />}
                  />
                </div>
              </div>
              <div className={classNames(styles.field, styles.large)}>
                <div className={styles.label}>ETA to port</div>
                <div className={classNames(styles.control, styles.eta)}>
                  <Input type="date" {...register("etaToPortStart")} />
                  <div className={styles.spliter}>~</div>
                  <Input type="date" {...register("etaToPortEnd")} />
                </div>
              </div>
            </div>
            <div className={styles.actions}>
              <Button variant="contained" size="small" type="submit">
                Apply
              </Button>
              <Button size="small" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default OrderFilter;
