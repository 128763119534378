.status {
  padding: 32px 0;
}
.formItems {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.meta {
  font-size: 12px;
  color: rgb(87, 96, 106);
}
.actions {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}
.dangerZone {
  margin-top: 32px;
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  padding: 32px;
  display: flex;
  align-items: center;
  gap: 32px;
}
.dangerZone .label {
  flex: 1;
}