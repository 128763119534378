import { Autocomplete, Box, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { forwardRef } from "react";
import useSWR from "swr";
import { Ssl } from "../../types";

interface Props {
  value: string | null;
  onChange: (value: string | null) => void;
}

const SslSelect = forwardRef(({ value, onChange }: Props, ref) => {
  const { data, error } = useSWR<Ssl[], AxiosError>("/ssls");

  const loading = !data && !error;

  const valueOption =
    value && data ? data.find(({ id }) => id === value) : null;

  return (
    <Autocomplete
      ref={ref}
      disablePortal
      autoHighlight
      value={valueOption}
      loading={loading}
      renderInput={(parameters) => (
        <TextField {...parameters} variant="standard" size="small" />
      )}
      getOptionLabel={({ name }) => name}
      renderOption={(props, { id, name }) => (
        <Box component="li" {...props} key={id}>
          {name}
        </Box>
      )}
      isOptionEqualToValue={({ id }, { id: id_ }) => id === id_}
      options={data ?? []}
      onChange={(_: unknown, value_) => {
        onChange(value_ ? value_.id : null);
      }}
    />
  );
});

export default SslSelect;
