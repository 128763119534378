import { Flash } from '@primer/react';
import styles from './index.module.css';

type Props = {
  title: string;
}

const GlobalError = ({ title }: Props) => (
  <div className={styles.main}>
    <Flash variant="danger">{title}</Flash>
  </div>
);

export default GlobalError;
