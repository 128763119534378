/* eslint-disable jsx-a11y/anchor-is-valid */
import { LightBulbIcon } from "@primer/octicons-react";
import {
  Breadcrumbs,
  ButtonPrimary,
  FormGroup,
  Link,
  TextInput,
} from "@primer/react";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { wrapOrderSerial } from "shared";
import SubmitSuccess from "../../components/submit-success";
import useDocumentColumns from "../../swr/use-document-columns";
import useTenant from "../../swr/use-tenant";
import { Document } from "../../types/document";
import { Order } from "../../types/order";
import styles from "./document.module.css";
import OrderDetailNavs from "./components/order-detail-navs";
import OrderBreadcrumbs from "./components/order-breadcrumbs";

const PageDocument = () => {
  const navigate = useNavigate();
  const { orderId, documentId } = useParams();
  const tenant = useTenant();
  const { data: order } = useSWR<Order>(`/orders/${orderId}`);
  const { data: document, mutate } = useSWR<Document>(
    `/orders/${orderId}/documents/${documentId}`
  );
  const { data: columns } = useDocumentColumns();
  const { handleSubmit, control, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  useEffect(() => {
    if (!document || !columns) {
      return;
    }

    const { data } = document;

    columns.forEach((column) => {
      if (data[column.id] !== undefined) {
        setValue(column.id, data[column.id]);
      }
    });
  }, [document, columns]);

  const onSubmit = async (data: unknown) => {
    setLoading(true);
    const { data: response } = await axios.put<Document>(
      `/orders/${orderId}/documents/${documentId}`,
      data
    );
    mutate(response);
    setLoading(false);
  };

  const handlePrint = async () => {
    if (!window.remote || !document || !order || !tenant || !columns) {
      return;
    }

    setPrintLoading(true);
    const orderNumber = wrapOrderSerial(tenant.short, order.number);
    const data: Record<string, string> = {};

    columns.forEach((column) => {
      data[column.label] = document.data[column.id]
        ? String(document.data[column.id])
        : "-";
    });

    try {
      await window.remote.printDocument(
        `${orderNumber} document`,
        {
          Time: moment().format("YYYY-MM-DD"),
          "Order Number": orderNumber,
        },
        data,
        document.attachments.map(encodeURI)
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        alert(e.message);
      }
    }

    setPrintLoading(false);
  };

  if (!orderId) {
    return null;
  }

  return (
    <div>
      <OrderBreadcrumbs orderId={orderId} />
      <div style={{ marginBottom: "16px" }} />
      <OrderDetailNavs current="document" orderId={orderId} />
      <div className={styles.page}>
        <div className={styles.main}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {columns?.map((column) => (
                <FormGroup key={column.id}>
                  <FormGroup.Label htmlFor={column.id}>
                    {column.label}
                  </FormGroup.Label>
                  <Controller
                    name={column.id}
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        type={column.type === "DATE" ? "date" : "text"}
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              ))}
            </div>
            <div className={styles.tip}>
              <LightBulbIcon /> Tip: Go to{" "}
              <RouterLink to="/settings">
                <Link href="#">settings</Link>
              </RouterLink>{" "}
              to manage columns.
            </div>
            <div>
              <ButtonPrimary type="submit" disabled={loading}>
                Save Changes
              </ButtonPrimary>
              <SubmitSuccess trigger={loading} />
            </div>
          </form>
        </div>
        <div className={styles.meta}>
          <h2>Actions</h2>
          {window.remote && (
            <ButtonPrimary disabled={printLoading} onClick={handlePrint}>
              Print
            </ButtonPrimary>
          )}
          <h2>Attachments</h2>
          <div>
            {document &&
              document.attachments.map((url) => (
                <div className={styles.attachment} key={url}>
                  <img src={url} alt="" />
                </div>
              ))}
          </div>
          <div>
            {document &&
              document.signatures.map((url) => (
                <div className={styles.signature} key={url}>
                  <img src={url} alt="" />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageDocument;
