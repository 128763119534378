.group {
  width: 280px;
}

.group.large {
  width: 560px;
}

.group.xlarge {
  width: 840px;
}

.group > label {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.group.red > label {
  color: red;
}

.group.green > label {
  color: green;
}
