import { Add } from "@mui/icons-material";
import { Card, CardContent, Fab, Tab, Tabs } from "@mui/material";
import axios from "axios";
import { useMemo } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { wrapInvoiceSerial } from "shared";
import useTenant from "../../../../swr/use-tenant";
import { Invoice } from "../../../../types";
import InvoiceForm from "./invoice-form";
import styles from "./invoice-group.module.css";

type Props = {
  isCost: boolean;
  invoices: Invoice[];
  orderId: string;
  mutate: () => void;
};

const InvoiceGroup = ({ isCost, invoices, orderId, mutate }: Props) => {
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const tenant = useTenant();
  const [isMultilineTabs, setIsMultilineTabs] = useState(false);
  const tabsRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (tabsRef.current) {
      // 有多行 tab 的时候，需要隐藏掉 active indicator, 否则会有UI上的问题
      setIsMultilineTabs(tabsRef.current.getBoundingClientRect().height > 50);
    }
  }, [invoices]);

  const handleAdd = async () => {
    setLoading(true);
    try {
      await axios.post(`/orders/${orderId}/invoices`, { isCost });
      mutate();
    } finally {
      setLoading(false);
    }
  };

  const invoice = useMemo(() => {
    if (current >= invoices.length) {
      return null;
    }

    return invoices[current];
  }, [current, invoices]);

  if (!tenant) {
    return null;
  }

  return (
    <Card className={styles.group}>
      <Tabs
        variant="standard"
        ref={tabsRef}
        TabIndicatorProps={
          isMultilineTabs ? { className: styles.tabIndicator } : undefined
        }
        className={styles.tabs}
        value={current}
        onChange={(_, number) => {
          setCurrent(number);
        }}
      >
        {invoices.map(({ number }) => (
          <Tab key={number} label={wrapInvoiceSerial(tenant.short, number)} />
        ))}
      </Tabs>
      <CardContent className={styles.content}>
        {invoice && (
          <div className={styles.form}>
            <InvoiceForm invoice={invoice} mutate={mutate} orderId={orderId} />
          </div>
        )}
        <div className={styles.fab}>
          <Fab
            color="primary"
            size="small"
            onClick={handleAdd}
            disabled={loading}
          >
            <Add />
          </Fab>
        </div>
      </CardContent>
    </Card>
  );
};

export default InvoiceGroup;
