import useSWR from "swr";
import { Terminal } from "../../types";

type Props = {
  addressId?: string;
}

const Address = ({ addressId }: Props) => {
  const { data } = useSWR<Terminal>(addressId ? `/addresses/${addressId}` : null);
  if (!data) {
    return <span />;
  }
  return <span>{data.name}</span>;
};

export default Address;
