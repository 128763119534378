import { ReactNode } from 'react';
import useProfile from '../../hooks/use-profile';
import GlobalError from '../global-error';

type Props = {
  children: ReactNode;
};

const Guard = ({ children }: Props) => {
  const user = useProfile();

  if (user.role === 'driver') {
    return <GlobalError title="Driver is not supported yet." />;
  }

  return children as JSX.Element;
};

export default Guard;
