import { Button, Card, IconButton, Modal as MModal } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ReactNode } from "react";
import styles from './index.module.css';

type Props = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  onOk?: () => boolean | Promise<boolean>;
}

const Modal = ({ open, onClose, children, title, onOk }: Props) => {
  const handleOk = async () => {
    if (!onOk) {
      onClose();

      return;
    }

    if (await onOk?.()) {
      onClose();
    }
  }

  return <MModal open={open} onClose={onClose}>
    <Card className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <IconButton onClick={onClose}><Close /></IconButton>
      </div>
      <div className={styles.body}>
        {children}
      </div>
      <div className={styles.footer}>
        <Button variant="outlined" onClick={onClose}>Close</Button>
        <Button variant="contained" onClick={handleOk}>Save</Button>
      </div>
    </Card>
  </MModal>
}

export default Modal;
