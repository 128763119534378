.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.table {
  height: 100%;
  flex: 1;
}

.row:hover {
  background: #f4f5fa;
}

.row.active {
  background: rgba(0, 0, 0, 0.08);
}

.cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
