import { Settings, Home, Group, AccountBalance, Storefront, Store, AlternateEmail, Warehouse, SportsScore, Stars } from "@mui/icons-material";
import { ReactNode } from "react";

interface Route {
  path: string;
  icon: ReactNode;
  title: string;
  roles?: string[];
}

const routes: Route[] = [
  {
    path: "/orders",
    icon: <Home />,
    title: "Home",
  },
  {
    path: "/customers",
    icon: <Storefront />,
    title: "Customers",
  },
  {
    path: "/vendors",
    icon: <Store />,
    title: "Vendors",
  },
  {
    path: "/users",
    icon: <Group />,
    title: "Manage Users",
    roles: ["admin"],
  },
  {
    path: "/settings",
    icon: <Settings />,
    title: "Settings",
  },
  {
    path: "/addresses",
    icon: <AlternateEmail />,
    title: "Address",
  },
  {
    path: "/depots",
    icon: <Warehouse />,
    title: "Depot",
  },
  {
    path: "/ssls",
    icon: <Stars />,
    title: "SSL",
  },
  {
    path: "/terminals",
    icon: <SportsScore />,
    title: "Terminal",
  },
  {
    path: "/accounting-items",
    icon: <AccountBalance />,
    title: "Accounting",
  },
];

export const basicRoutes: Route[] = routes.filter((route) => !route.roles);

export const adminRoutes: Route[] = routes;

export const getActiveRoute = (
  pathname: string | undefined
): Route | undefined => {
  if (!pathname) {
    return undefined;
  }

  if (pathname === "/") {
    return routes.find((route) => route.path === pathname);
  }

  return routes
    .filter((route) => route.path !== "/")
    .find((route) => pathname.startsWith(route.path));
};
