import { Button } from "@mui/material";
import axios from "axios";
import { useRef, useState } from "react";
import styles from "./style.module.css";

interface Props {
  value?: string;
  onChange?: (url: string) => void;
}

export default function ImageUpload({ value, onChange }: Props) {
  const fileInput = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const selectedFile = e.target.files?.[0];

    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("name", "file");
    formData.append("file", selectedFile);

    setLoading(true);

    const { data } = await axios.post<{ url: string }>(
      "/user-assets",
      formData
    );

    setLoading(false);

    if (onChange) {
      onChange(data.url);
    }
  };

  const handleClick = () => {
    fileInput.current?.click();
  };

  return (
    <div className={styles.wrapper}>
      {value && <img src={value} alt={value} className={styles.image} />}
      <input
        className={styles.input}
        type="file"
        onChange={handleFileChange}
        ref={fileInput}
      />
      <Button
        className={styles.button}
        onClick={handleClick}
        disabled={loading}
      >
        {loading ? "uploading" : "Upload Image"}
      </Button>
    </div>
  );
}
