import { Profile } from '../types';
import { getProfile } from '../utils/get-profile';

const useProfile = (): Profile => {
  const profile = getProfile();

  if (!profile) {
    window.location.assign('/sign-in');

    throw new Error('Unauthorized');
  }

  return profile;
};

export default useProfile;
