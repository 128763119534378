import { Chip, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { forwardRef } from "react";
import FormGroup from "../../../components/form-group";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const getColor = (value: string): "default" | "red" | "green" => {
  if (value === "hold") {
    return "red";
  }

  if (value === "release") {
    return "green";
  }

  return "default";
};

const getValue = (value: string): "none" | "hold" | "release" => {
  if (value === "none" || value === "hold" || value === "release") {
    return value;
  }

  return "none";
};

const Available = forwardRef(({ value, onChange }: Props, ref) => {
  const color = getColor(value);
  const radioValue = getValue(value);

  return (
    <FormGroup label="Available" color={color}>
      <RadioGroup
        row
        ref={ref}
        value={radioValue}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      >
        <FormControlLabel
          value="none"
          control={<Radio size="small" />}
          label={<Chip size="small" color="default" label="none" />}
        />
        <FormControlLabel
          value="hold"
          control={<Radio size="small" />}
          label={
            <Chip
              size="small"
              color={radioValue === "hold" ? "error" : "default"}
              label="hold"
            />
          }
        />
        <FormControlLabel
          value="release"
          control={<Radio size="small" />}
          label={
            <Chip
              size="small"
              color={radioValue === "release" ? "success" : "default"}
              label="release"
            />
          }
        />
      </RadioGroup>
    </FormGroup>
  );
});

export default Available;
