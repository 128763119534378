import useSWR from "swr";
import { Terminal } from "../../types";

type Props = {
  terminalId?: string;
}

const Terminal = ({ terminalId }: Props) => {
  const { data } = useSWR<Terminal>(terminalId ? `/terminals/${terminalId}` : null);
  if (!data) {
    return <span />;
  }
  return <span>{data.name}</span>;
};

export default Terminal;
