import { Button, Input } from "@mui/material";
import { useFormContext } from "react-hook-form";
import FormGroup from "../form-group";
import { CommonForm } from "./types";
import styles from "./index.module.css";

type Props = {
  submitLabel: string;
};

const CommonFormFields = ({ submitLabel }: Props) => {
  const {
    register,
    formState: { isSubmitting },
  } = useFormContext<CommonForm>();

  return (
    <>
      <div className={styles.fields}>
        <FormGroup label="Name">
          <Input
            fullWidth
            id="name"
            {...register("name", { required: true })}
          />
        </FormGroup>
        <FormGroup label="Short">
          <Input fullWidth id="short" {...register("short")} />
        </FormGroup>
        <FormGroup label="Address">
          <Input fullWidth multiline id="address" {...register("address")} />
        </FormGroup>
        <FormGroup label="Email">
          <Input fullWidth id="email" {...register("email")} />
        </FormGroup>
        <FormGroup label="Phone">
          <Input fullWidth id="phone" {...register("phone")} />
        </FormGroup>
        <FormGroup label="ContactPerson">
          <Input fullWidth id="contactPerson" {...register("contactPerson")} />
        </FormGroup>
      </div>
      <Button variant="contained" type="submit" disabled={isSubmitting}>
        {submitLabel}
      </Button>
    </>
  );
};

export default CommonFormFields;
