import { Breadcrumbs } from "@primer/react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { Customer } from "../../types";
import CommonFormFields from "../../components/common-form-fields";
import { CommonForm } from "../../components/common-form-fields/types";
import { basePath } from "./constants";

const PageCreateAddress = () => {
  const navigate = useNavigate();
  const formMethods = useForm<CommonForm>();
  const { handleSubmit } = formMethods;

  const onSubmit = async (data: CommonForm) => {
    const {
      data: { id },
    } = await axios.post<Customer>(basePath, data);
    navigate(`${basePath}/${id}`);
  };

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item href="#" onClick={() => navigate(basePath)}>
          Addresses
        </Breadcrumbs.Item>
        <Breadcrumbs.Item selected>Create</Breadcrumbs.Item>
      </Breadcrumbs>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CommonFormFields submitLabel="Create" />
        </form>
      </FormProvider>
    </div>
  );
};

export default PageCreateAddress;
