import moment from "moment";
import { Link } from "react-router-dom";
import { DataTableColumn } from "../../components/data-table/types";
import Driver from "../../components/driver";
import Terminal from "../../components/terminal";
import { OrderContainer } from "../../types";
import Address from "../../components/address";

export const defaultOrderColumnIds = ["number", "mbl", "cntr", "ref", "eta"];

export const orderColumns: DataTableColumn[] = [
  {
    label: "#",
    id: "number",
    width: 120,
    sortable: true,
    render: (data, row) => <Link to={`/orders/${row.id}`}>{data}</Link>,
  },
  {
    label: "MBL",
    id: "mbl",
    width: 100,
    sortable: false,
  },
  {
    label: "CNTR",
    id: "cntr",
    width: 100,
    sortable: false,
  },
  {
    label: "REF",
    id: "ref",
    width: 100,
    sortable: false,
  },
  {
    label: "ETA",
    width: 100,
    id: "etaToPort",
    sortable: true,
  },
  {
    label: "ETA Discharged",
    id: "etaDischarged",
    width: 100,
    sortable: false,
  },
  {
    label: "Appointment",
    id: "appointment",
    width: 100,
    sortable: false,
  },
  {
    label: "Size",
    id: "size",
    width: 100,
    sortable: false,
  },
  {
    label: "Available",
    id: "available",
    width: 100,
    sortable: false,
  },
  {
    label: "Status",
    id: "status",
    width: 100,
    sortable: false,
  },
  {
    label: "Driver",
    id: "driverUserId",
    width: 100,
    sortable: false,
    render: (data) => <Driver userId={data as string} />,
  },
  {
    label: "LFD",
    id: "containers",
    width: 100,
    sortable: false,
    render: (data) =>
      (data as OrderContainer[])
        .filter((item) => item.lfd)
        .map((item) => {
          const date = moment(item.lfd);
          if (!date.isValid()) {
            return;
          }
          return date.format("YYYY-MM-DD");
        })
        .filter(Boolean)
        .join(" "),
  },
  {
    id: "erd",
    label: "ER D",
    name: "containers",
    width: 100,
    sortable: false,
    render: (data) =>
      (data as OrderContainer[])
        .filter((item) => item.emptyReturnDate)
        .map((item) => {
          const date = moment(item.emptyReturnDate);
          if (!date.isValid()) {
            return;
          }
          return date.format("YYYY-MM-DD");
        })
        .filter(Boolean)
        .join(" "),
  },
  {
    label: "Terminal",
    id: "terminalId",
    width: 100,
    sortable: false,
    render: (data) => <Terminal terminalId={data as string} />,
  },
  {
    label: "Address",
    id: "addressId",
    width: 100,
    sortable: true,
    render: (data) => <Address addressId={data as string} />,
  },
];
