import { useParams } from "react-router";
import useSWR from "swr";
import { Invoice } from "../../../types";
import OrderBreadcrumbs from "../components/order-breadcrumbs";
import OrderDetailNavs from "../components/order-detail-navs";
import InvoiceGroup from "./components/invoice-group";
import styles from './index.module.css';

const PageInvoices = () => {
  const { orderId } = useParams();
  const { data: invoices, mutate } = useSWR<Invoice[]>(
    orderId ? `/orders/${orderId}/invoices` : null
  );

  if (!orderId || !invoices) {
    return null;
  }

  return (
    <div>
      <OrderBreadcrumbs orderId={orderId} />
      <div style={{ marginBottom: "16px" }} />
      <OrderDetailNavs current="invoice" orderId={orderId} />
      <div className={styles.groups}>
        <InvoiceGroup
          orderId={orderId}
          invoices={invoices.filter(({ isCost }) => isCost)}
          isCost
          mutate={mutate}
        />
        <InvoiceGroup
          orderId={orderId}
          invoices={invoices.filter(({ isCost }) => !isCost)}
          isCost={false}
          mutate={mutate}
        />
      </div>
    </div>
  );
};

export default PageInvoices;
