import { Button, FormControlLabel, TextField, Checkbox } from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { setApiToken } from "../axios-config";
import { isApiError } from "../utils/api-error";

import styles from "./sign-in.module.css";

type SignInForm = {
  email: string;
  password: string;
  remember: boolean;
};

const PageSignIn = () => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
    reset,
    control,
  } = useForm<SignInForm>({
    defaultValues: {
      remember: false,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      reset({
        email,
        password: "",
        remember: true,
      });
    }
  }, [reset]);

  const onSubmit = async (formData: SignInForm) => {
    try {
      const { remember, ...body } = formData;
      const {
        data: { token },
      } = await axios.post<{ token: string }>("/sign-in", body);
      localStorage.setItem("shark-token", token);
      setApiToken(token);

      if (remember) {
        localStorage.setItem("email", body.email);
      } else {
        localStorage.removeItem("email");
      }

      navigate("/");
    } catch (error: unknown) {
      if (isApiError(error)) {
        enqueueSnackbar(error.response.data.message, { variant: "error" });

        return;
      }

      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <div className={styles.page}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.formRow}>
          <TextField
            fullWidth
            label="EMAIL"
            type="email"
            {...register("email")}
          />
        </div>
        <div className={styles.formRow}>
          <TextField
            fullWidth
            label="PASSWORD"
            type="password"
            {...register("password")}
          />
        </div>
        <div className={styles.formRow}>
          <Controller
            control={control}
            name="remember"
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={(event) => {
                      onChange(event.target.checked);
                    }}
                  />
                }
                label="Remember Email"
              />
            )}
          />
        </div>
        <div className={classNames(styles.formRow, styles.submit)}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Sign In
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PageSignIn;
