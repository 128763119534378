import { useEffect, useState } from "react";

export default function useLocalConfigs<T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] {
  const [data, setData] = useState<T>(defaultValue);

  useEffect(() => {
    const raw = localStorage.getItem(key);

    if (raw) {
      try {
        setData(JSON.parse(raw));
      } catch {
        setData(defaultValue);
      }
    }
  }, []);

  return [
    data,
    (value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
      setData(value);
    },
  ];
}
