import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import { useMemo } from "react";
import { useConstants } from "../../swr/use-constants";
import { StatusLog } from "../../types";
import Driver from "../driver";
import styles from "./index.module.css";

interface Props {
  value: string;
  onChange: (value: string) => void;
  statusLogs: StatusLog[];
}

const StatusBar = ({ value, onChange, statusLogs }: Props) => {
  const constants = useConstants();

  const handleChange = (status: string) => {
    if (!constants) {
      return;
    }

    onChange(status);
  };

  const logs = useMemo(() => {
    if (!constants) {
      return [];
    }

    const sortedLogs = statusLogs.slice().sort((log1, log2) => {
      const time1 = moment(log1.createdAt);
      const time2 = moment(log2.createdAt);

      return time2.unix() - time1.unix();
    });

    const currentIndex = constants.statusEnums.indexOf(value);

    const availableLogs = sortedLogs.filter(({ status }) => {
      const index = constants.statusEnums.indexOf(status);

      return index <= currentIndex;
    });

    return availableLogs.reduce<StatusLog[]>((prev, log) => {
      if (prev.some(({ status }) => status === log.status)) {
        return prev;
      }

      return [...prev, log];
    }, []);
  }, [statusLogs, value, constants]);

  if (!constants) {
    return null;
  }

  return (
    <div>
      <Stepper
        activeStep={constants.statusEnums.indexOf(value)}
        alternativeLabel
      >
        {constants.statusEnums.map((title, index) => {
          const log = logs.find(({ status }) => status === title);
          const label = constants.statusLabels[index];
          const lastStep = index === constants.statusEnums.length - 1;

          return (
            <Step
              expanded
              key={title}
              onClick={() => {
                handleChange(title);
              }}
            >
              <StepLabel className={styles.label}>
                <div>{title}</div>
                {label && (
                  <div
                    className={classNames(
                      styles.nextLabel,
                      lastStep && styles.last
                    )}
                  >
                    {label}
                  </div>
                )}
                {log && (
                  <div className={styles.content}>
                    <Driver userId={log.userId} />{" "}
                    <div className={styles.date}>
                      {moment(log.createdAt).format("MM/DD")}
                    </div>
                  </div>
                )}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default StatusBar;
