export type DocumentColumn = {
  id: string;
  label: string;
  type: DocumentColumnType;
  tenantId: string;
  order: number;
};

export type DocumentColumnType = 'INPUT' | 'TEXT' | 'DATE';

export const documentColumnTypes: DocumentColumnType[] = [
  'INPUT',
  'TEXT',
  'DATE',
];

export const documentColumnTypeLabels = [
  'Input Field',
  'Multiline Text',
  'Date Select',
];
