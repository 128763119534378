import { CircularProgress } from '@mui/material';
import styles from './index.module.css';

const GlobalLoading = () => (
  <div className={styles.main}>
    <CircularProgress />
  </div>
);

export default GlobalLoading;
