import { CheckIcon } from '@primer/octicons-react';
import { useEffect, useRef, useState } from 'react';
import styles from './style.module.css';

interface Props {
  trigger: boolean;
}

const SubmitSuccess = ({ trigger }: Props) => {
  const [show, setShow] = useState(false);
  const initedRef = useRef(false);

  useEffect(() => {
    if (!trigger) {
      if (!initedRef.current) {
        initedRef.current = true;
        return;
      }
      setShow(true);
      setTimeout(() => setShow(false), 3000);
    }
  }, [trigger]);

  if (!show) {
    return null;
  }

  return (
    <span className={styles.main}>
      <CheckIcon /> Done
    </span>
  );
};

export default SubmitSuccess;
