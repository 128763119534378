.wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.card {
  width: 600px;
  margin: 100px auto 30px;
}

.content {
  padding: 16px;
}

.footer {
  padding: 16px;
  border-top: 1px solid #d9dee3;
  display: flex;
  justify-content: right;
  gap: 16px;
}

.item {
  border: 1px solid #d9dee3;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  flex: 1;
}

.width {
  width: 100px;
}
