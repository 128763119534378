import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL ?? 'http://localhost:3001/api';

export const setApiToken = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default async function axiosConfig() {
  const token = localStorage.getItem('shark-token');
  if (token) {
    setApiToken(token);
  }
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.response.use(
    (response) => response,
    (error: unknown) => {
      if (
        axios.isAxiosError(error) &&
        (error.response?.status === 401 || error.response?.status === 403)
      ) {
        window.location.assign('/sign-in');
      }

      return Promise.reject(error);
    }
  );
}
