.footer {
  padding: 8px;
  border-top: 1px solid #d9dee3;
  margin-top: -1px;
  display: flex;
  align-items: center;
}

.actions {
  flex: 1;
}

.pagination > div {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.pagination > div p {
  margin-bottom: 0;
}
