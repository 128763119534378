import moment from "moment";

export const remoteDateToLocalInput = (dateString: string | null) => {
  if (!dateString) {
    return "";
  }

  const date = moment(dateString);

  if (!date.isValid()) {
    return "";
  }

  return date.format("YYYY-MM-DD");
};

export const localInputDateToRemote = (dateString: string) => {
  if (!dateString) {
    return null;
  }

  const date = moment(dateString);

  if (!date.isValid()) {
    throw new Error(`Invalid date input: ${dateString}`);
  }

  return date.startOf("day").toISOString();
};
