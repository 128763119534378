import { Breadcrumbs } from "@primer/react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import { Customer } from "../../types";
import styles from "./detail.module.css";
import FormGroup from "../../components/form-group";
import { Button, Input } from "@mui/material";
import { useSnackbar } from "notistack";
import InvoiceItemCombo from "../../components/invoice-item-combo";

const PageCustomer = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { data: customer, mutate } = useSWR<Customer>(
    customerId ? `/customers/${customerId}` : null
  );
  const { handleSubmit, register, setValue, control } = useForm<Customer>({
    defaultValues: {
      invoiceItems: [],
    },
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!customer) {
      return;
    }

    const { name, code, phone, email, address, contact, invoiceItems } =
      customer;
    setValue("name", name);
    setValue("code", code);
    setValue("phone", phone);
    setValue("email", email);
    setValue("address", address);
    setValue("contact", contact);
    setValue("invoiceItems", invoiceItems ?? []);
  }, [customer]);

  const onSubmit = async (data: Customer) => {
    setLoading(true);
    const { data: response } = await axios.put<Customer>(
      `/customers/${customerId}`,
      {
        ...data,
        invoiceItems: data.invoiceItems.map((item) => {
          if (item.id.startsWith("temp-")) {
            const { id, ...others } = item;
            return others;
          }

          return item;
        }),
      }
    );
    mutate(response);
    setLoading(false);
    enqueueSnackbar("Saved", { variant: "success" });
  };

  return (
    <div>
      <Breadcrumbs>
        <Breadcrumbs.Item href="#" onClick={() => navigate("/customers")}>
          Customers
        </Breadcrumbs.Item>
        <Breadcrumbs.Item selected>
          {customer && customer.name}
        </Breadcrumbs.Item>
      </Breadcrumbs>
      {customer && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.fields}>
            <FormGroup label="Name">
              <Input
                fullWidth
                id="name"
                {...register("name", { required: true })}
              />
            </FormGroup>
            <FormGroup label="Code">
              <Input
                fullWidth
                id="code"
                {...register("code", { required: true })}
              />
            </FormGroup>
            <FormGroup label="Address">
              <Input
                fullWidth
                multiline
                id="address"
                {...register("address")}
              />
            </FormGroup>
            <FormGroup label="Email">
              <Input fullWidth id="email" {...register("email")} />
            </FormGroup>
            <FormGroup label="Phone">
              <Input fullWidth id="phone" {...register("phone")} />
            </FormGroup>
            <FormGroup label="Contact">
              <Input
                fullWidth
                multiline
                id="contact"
                {...register("contact")}
              />
            </FormGroup>
            <FormGroup label="Invoice items" size="xlarge">
              <Controller
                control={control}
                name="invoiceItems"
                render={({ field }) => (
                  <InvoiceItemCombo isCost={false} {...field} />
                )}
              />
            </FormGroup>
          </div>
          <Button variant="contained" type="submit" disabled={loading}>
            Update
          </Button>
        </form>
      )}
    </div>
  );
};

export default PageCustomer;
