import {
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import classNames from "classnames";
import { ReactNode } from "react";
import Footer from "./footer";
import Head from "./head";
import styles from "./style.module.css";
import { DataTableColumn, DataTableColumnConfig, DataTableRow, DataTableSorter } from "./types";

type Props = {
  pageSize: number;
  columns: DataTableColumn[];
  isLoading: boolean;
  pageIndex: number;
  totalCount: number;
  activeId?: string;
  rows: DataTableRow[];
  allColumns?: DataTableColumn[];
  configColumns?: DataTableColumnConfig[];
  onConfigColumnsChange?: (value: DataTableColumnConfig[]) => void;
  onActiveIdChange?: (value: string) => void;
  onClick?: (value: string) => void;
  sorter?: DataTableSorter;
  onSorterChange?: (id: string, direction: "asc" | "desc") => void;
  footer?: ReactNode;
  onPageIndexChange: (value: number) => void;
  onPageSizeChange: (value: number) => void;
};

const DataTable = ({
  pageSize,
  columns,
  isLoading,
  activeId,
  onActiveIdChange,
  rows,
  onClick,
  sorter,
  onSorterChange,
  footer,
  pageIndex,
  totalCount,
  onPageIndexChange,
  onPageSizeChange,
  allColumns,
  configColumns,
  onConfigColumnsChange,
}: Props) => {
  return (
    <Card className={styles.container}>
      <TableContainer className={styles.table}>
        <Table stickyHeader size="small">
          <Head
            columns={columns}
            sorter={sorter}
            onSorterChange={onSorterChange}
          />
          <TableBody>
            {isLoading &&
              Array.from({ length: pageSize }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell colSpan={columns.length}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
            {!isLoading &&
              rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={classNames(
                    styles.row,
                    activeId === row.id && styles.active
                  )}
                  onClick={() => {
                    onActiveIdChange?.(row.id);
                  }}
                  onDoubleClick={() => {
                    onActiveIdChange?.(row.id);
                    onClick?.(row.id);
                  }}
                >
                  {columns.map((column) => {
                    const data = row[column.name ?? column.id] as ReactNode;

                    return (
                      <TableCell key={column.id} padding="none">
                        <div
                          style={{
                            width: `${column.width}px`,
                            padding: "4px 8px",
                          }}
                        >
                          <div className={styles.cell}>
                            {column.render ? column.render(data, row) : data}
                          </div>
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Footer
        footer={footer}
        current={pageIndex}
        count={totalCount}
        pageSize={pageSize}
        isLoading={isLoading}
        onPageChange={onPageIndexChange}
        onPageSizeChange={onPageSizeChange}
        allColumns={allColumns}
        configColumns={configColumns}
        onConfigColumnsChange={onConfigColumnsChange}
      />
    </Card>
  );
};

export default DataTable;
