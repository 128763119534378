import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Button, Card, Modal, Slider, Switch } from "@mui/material";
import { useMemo } from "react";
import styles from "./config.module.css";
import { DataTableColumn, DataTableColumnConfig } from "./types";

type Props = {
  visible: boolean;
  onClose: () => void;
  value: DataTableColumnConfig[];
  onChange: (value: DataTableColumnConfig[]) => void;
  source: DataTableColumn[];
};

const Config = ({ visible, onClose, value, onChange, source }: Props) => {
  const items = useMemo(() => {
    return source
      .map((column) => {
        const configColumn = value.find(({ id }) => id === column.id);

        if (configColumn) {
          return {
            ...configColumn,
            label: column.label,
          };
        }

        return {
          id: column.id,
          enabled: false,
          width: column.width,
          order: 999,
          label: column.label,
        };
      })
      .sort((itemA, itemB) => itemA.order - itemB.order);
  }, [value, source]);

  const handleItemChange = (
    id: string,
    value: Partial<DataTableColumnConfig>
  ) => {
    const changedItems = items
      .sort((itemA, itemB) => itemA.order - itemB.order)
      .map((item, index) => ({
        id: item.id,
        enabled: item.enabled,
        width: item.width,
        order: index,
      }))
      .map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          ...value,
        };
      });
    onChange(changedItems);
  };

  const handleItemOrderChange = (id: string, direction: "up" | "down") => {
    const sortedItems = items
      .sort((itemA, itemB) => itemA.order - itemB.order)
      .map((item, index) => ({
        id: item.id,
        enabled: item.enabled,
        width: item.width,
        order: index,
      }));
    const sourceItemIndex = sortedItems.findIndex((item) => item.id === id);
    const targetItemIndex =
      direction === "up" ? sourceItemIndex - 1 : sourceItemIndex + 1;
    console.log(sortedItems, sourceItemIndex, targetItemIndex);
    const sourceItem = sortedItems[sourceItemIndex];
    const targetItem = sortedItems[targetItemIndex];

    if (!sourceItem || !targetItem) {
      return;
    }

    const changedItems = sortedItems.map((item) => {
      if (item.id === sourceItem.id) {
        return {
          ...item,
          order: targetItem.order,
        };
      }

      if (item.id === targetItem.id) {
        return {
          ...item,
          order: sourceItem.order,
        };
      }

      return item;
    });
    onChange(changedItems);
  };

  return (
    <Modal open={visible} onClose={onClose}>
      <div className={styles.wrapper}>
        <Card className={styles.card}>
          <div className={styles.content}>
            <div className={styles.items}>
              {items.map((item) => (
                <div key={item.id} className={styles.item}>
                  <div className={styles.switch}>
                    <Switch
                      checked={item.enabled}
                      onChange={(_, checked) => {
                        handleItemChange(item.id, { enabled: checked });
                      }}
                    />
                  </div>
                  <div className={styles.label}>{item.label}</div>
                  <div className={styles.width}>
                    <Slider
                      min={40}
                      step={5}
                      max={200}
                      valueLabelDisplay="auto"
                      value={item.width}
                      onChange={(_, value) => {
                        handleItemChange(item.id, { width: value as number });
                      }}
                    />
                  </div>
                  <div className={styles.order}>
                    <Button
                      size="small"
                      onClick={() => {
                        handleItemOrderChange(item.id, "up");
                      }}
                    >
                      <ArrowUpward />
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        handleItemOrderChange(item.id, "down");
                      }}
                    >
                      <ArrowDownward />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.footer}>
            <Button onClick={onClose} variant="contained">
              Close
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default Config;
