import axios from 'axios';

export type ApiError = {
  response: {
    status: number;
    data: {
      error: string;
      message: string;
    };
  };
};

export function isApiError(error: unknown): error is ApiError {
  if (!axios.isAxiosError(error)) {
    return false;
  }

  return true;
}
