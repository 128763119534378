import { Card, CardContent, Input, MenuItem, Select } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import CntrUseDays from "./CntrUseDays";
import TBillingDays from "./TBillingDays";
import TerminalDays from "./TerminalDays";
import { OrderContainerForm } from "../../types";
import styles from "./index.module.css";
import FormGroup from "../../../../components/form-group";
import { FormProvider, useForm } from "react-hook-form";

type Props = {
  value: OrderContainerForm[];
  cntr?: string;
  onChange: (value: OrderContainerForm[]) => void;
  caseDoneDate?: string;
};

const defaultItemValue = {
  lfd: "",
  pickupDate: "",
  deliverDate: "",
  emptyReturnDate: "",
};

const TimeZone = ({ value, cntr, caseDoneDate, onChange }: Props) => {
  const [current, setCurrent] = useState<number>(0);
  const formMethods = useForm<OrderContainerForm>();
  const {
    reset,
    watch,
    formState: { isDirty },
    register,
  } = formMethods;
  const formValues = watch();

  const options = useMemo(() => {
    if (!cntr) {
      return [];
    }

    return cntr.trim().split(" ").filter(Boolean);
  }, [cntr]);
  const optionValue = useMemo(() => options[current], [cntr, current]);

  useEffect(() => {
    if (options.length === 0) {
      return;
    }

    setCurrent((previous) => {
      if (previous > options.length - 1) {
        return 0;
      }

      return previous;
    });
  }, [options]);

  useEffect(() => {
    const option = options[current];

    if (!option) {
      return;
    }

    const formData = value[current] ?? {
      ...defaultItemValue,
      cntr: option,
    };
    reset(formData);
  }, [current, value]);

  useEffect(() => {
    if (isDirty && options[current]) {
      const newValue = options.map((option, index) => {
        const originalData = value.find(({ cntr }) => cntr === option) ?? {
          ...defaultItemValue,
          cntr: option,
        };

        if (index !== current) {
          return originalData;
        }

        return {
          ...originalData,
          ...formValues,
          cntr: option,
        };
      });
      onChange(newValue);
    }
  }, [current, isDirty, formValues, onChange, value]);

  return (
    <Card variant="outlined" className={styles.container}>
      <CardContent>
        <FormGroup label="CNTR">
          <Select
            fullWidth
            size="small"
            variant="standard"
            value={optionValue ?? "non"}
            onChange={(event) => {
              setCurrent(
                options.findIndex((option) => option === event.target.value) ??
                  0
              );
            }}
          >
            {options.length === 0 && (
              <MenuItem value="none">SELECT CNTR</MenuItem>
            )}
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        {optionValue ? (
          <FormProvider {...formMethods}>
            <form>
              <FormGroup label="LFD">
                <Input
                  fullWidth
                  type="date"
                  size="small"
                  {...register("lfd")}
                />
              </FormGroup>
              <div className={styles.line}>
                <FormGroup label="P/U D">
                  <Input
                    fullWidth
                    type="date"
                    size="small"
                    {...register("pickupDate")}
                  />
                </FormGroup>
                <TerminalDays />
              </div>
              <div className={styles.line}>
                <FormGroup label="DELI D">
                  <Input
                    fullWidth
                    type="date"
                    size="small"
                    {...register("deliverDate")}
                  />
                </FormGroup>
                <CntrUseDays />
              </div>
              <div className={styles.line}>
                <FormGroup label="ER D">
                  <Input
                    fullWidth
                    type="date"
                    size="small"
                    {...register("emptyReturnDate")}
                  />
                </FormGroup>
                <TBillingDays caseDoneDate={caseDoneDate} />
              </div>
            </form>
          </FormProvider>
        ) : (
          <div className={styles.empty}>PLEASE FILL IN CNTR</div>
        )}
      </CardContent>
    </Card>
  );
};

export default TimeZone;
